import { useInfiniteQuery } from "@tanstack/react-query";

export default function useInfinityScrolling(queryKey, getData) {
    const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
        queryKey: [queryKey],

        queryFn: ({ pageParam = 1 }) => getData(pageParam),

        getNextPageParam: (lastPage, allPage) => {
            const nextPage = allPage.length + 1;

            return lastPage.result.length === 0 ? undefined : nextPage;
        },
    });

    const resultOnly = data?.pages.map((item) => item.result);
    const resultData = resultOnly?.flat();

    return {
        resultData,
        fetchNextPage,
        hasNextPage,
        isLoading,
    };
}
