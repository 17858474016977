import styled from "@emotion/styled";
import Text from "Components/Text/Text";
import { useRef } from "react";
import * as S from "./RegisterOption.style";

export default function RegisterThumbnail({ control, setValue, watch }) {
    const imgRef = useRef();
    const thumbnail = watch("thumbnail");
    const saveImgFile = () => {
        const file = imgRef.current.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        console.log(file);
        reader.onloadend = () => {
            setValue("thumbnail", reader.result);
        };
    };

    return (
        <S.Wrapper align="flex-start">
            <Text type="h6" subType="p">
                대표 이미지
            </Text>
            <S.InputContainer>
                <InputThumbnailBox>
                    <Label
                        htmlFor="thumbnail"
                        url={thumbnail}
                        // style={{
                        //     background: thumbnail
                        //         ? thumbnail
                        //         : `url(${process.env.PUBLIC_URL}/assets/thumbnail/registerThumbnail.svg) no-repeat center center`,
                        // }}
                    >
                        <Thumbnail
                            id="thumbnail"
                            type="file"
                            control={control}
                            name="thumbnail"
                            ref={imgRef}
                            onChange={saveImgFile}
                        />
                    </Label>
                    <Text type="h5" subType="p">
                        500 x 500px
                    </Text>
                </InputThumbnailBox>
            </S.InputContainer>
        </S.Wrapper>
    );
}
const InputThumbnailBox = styled.div`
    & > p {
        color: ${({ theme }) => theme.COLOR.common.gray[400]};
    }
`;
const Label = styled.label`
    display: block;
    width: 90px;
    height: 90px;
    margin-bottom: 4px;
    border: 1px solid ${({ theme }) => theme.COLOR.common.gray[500]};
    background: ${({ url }) =>
            url
                ? `url(${url})`
                : `url(${process.env.PUBLIC_URL}/assets/thumbnail/registerThumbnail.svg)`}no-repeat
        center center;
    cursor: pointer;
    background-size: ${({ url }) => (url ? "contain" : null)};
`;
const Thumbnail = styled.input`
    display: none;
`;
