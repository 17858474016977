import RadioBox from "Components/RadioBox/RadioBox";
import Text from "Components/Text/Text";
import { soldState } from "Utils/soldState";
import * as S from "./RegisterOption.style";

export default function RegisterSoldState({ control }) {
    return (
        <S.Wrapper>
            <Text type="h6" subType="p">
                판매 여부
            </Text>
            <S.InputContainer>
                <div>
                    <RadioBox
                        control={control}
                        name="sold_state"
                        options={soldState}
                    />
                </div>
            </S.InputContainer>
        </S.Wrapper>
    );
}
