import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import Text from "Components/Text/Text";
import { FlexAlignStart, FlexJustifyBetween } from "Styles/common";
import theme from "Styles/theme";

export default function Thumbnail(props) {
    const { url, store } = props;

    return (
        <S.Wrapper>
            <ThumbnailContainer url={url} />
            <S.AgencyContainer>
                <S.AgencyList>
                    <AgencyProfileBox profileUrl={store.profileUrl} />
                    <div>
                        <Text type="h5">{store.name}</Text>
                        <Text type="h5" subType="p">
                            스토어 간단소개문
                        </Text>
                    </div>
                </S.AgencyList>
                <Button styles="outline">문의하기</Button>
            </S.AgencyContainer>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
`;
const ThumbnailContainer = styled.div`
    padding-bottom: 100%;
    background: ${({ url }) => `url(${url}) no-repeat center center`};
    background-size: cover;
    margin-bottom: 24px;
    border-radius: 16px;
`;
const AgencyContainer = styled.div`
    ${FlexJustifyBetween}
`;

const AgencyList = styled.div`
    ${FlexAlignStart}
    gap: 8px;

    & > div:last-of-type > h5 {
        margin-bottom: 8px;
    }
`;

const AgencyProfileBox = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${({ profileUrl }) =>
        !profileUrl
            ? theme.COLOR.common.gray[300]
            : `url(${profileUrl}) no-repeat center center`};
`;

const S = {
    Wrapper,
    AgencyContainer,
    AgencyList,
};
