import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import IconCollection from "../Icons/IconCollection";
import * as S from "./Modal.style";

export default function Modal(props) {
    const { state, ...rest } = props;
    const navigate = useNavigate();

    const location = useLocation();
    const { pathname } = location;

    const onNavigate = () => {
        if (pathname.includes("signup")) {
            navigate("/login");
        }

        // pathname에 따른 조건문 추가
    };

    return (
        <S.Wrapper>
            <S.Modal>
                <IconCollection
                    size="modal"
                    color={state}
                    children={
                        state === "positive"
                            ? "check"
                            : state === "negative"
                            ? "priority_high"
                            : "question_mark"
                    }
                />
                <S.Message>응답 메시지 내용</S.Message>
                <Button color="common" onClick={onNavigate}>
                    url 경로에 따라 처리
                </Button>
            </S.Modal>
        </S.Wrapper>
    );
}
