import styled from "@emotion/styled";
import FormElement from "Components/FormElement/FormElement";
import Text from "Components/Text/Text";
import { FlexAlignStart, FlexBetween } from "Styles/common";

export default function OtherRequirement({ control }) {
    return (
        <S.Wrapper>
            <S.InputContainer>
                <Text type="h6" subType="p">
                    요구사항
                </Text>
                <FormElement
                    control={control}
                    name="pay_requirement"
                    placeholder={"요구사항을 입력해주세요."}
                />
            </S.InputContainer>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    ${FlexAlignStart}
    flex-direction: column;
    width: 100%;
    gap: 24px;
`;

const InputContainer = styled.div`
    position: relative;
    ${FlexBetween}
    width: 100%;
    gap: 16px;

    & > p {
        flex-shrink: 0;
    }
`;

const S = {
    Wrapper,
    InputContainer,
};
