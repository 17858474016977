import styled from "@emotion/styled";
import Hr from "Components/Hr/Hr";
import Text from "Components/Text/Text";
import useCountState from "Hooks/useCountState";
import { useEffect, useRef, useState } from "react";
import { StickyPaymentBar } from "./Components/StickyPaymentBar";

export default function ProductInfo(props) {
    const { product } = props;
    const { count, setCount } = useCountState();
    const [heightValue, setHeightValue] = useState();

    const descBoxRef = useRef(null);

    useEffect(() => {
        if (descBoxRef.current) {
            setHeightValue(descBoxRef.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        setCount(1);
    }, [product]);

    return (
        <S.Wrapper>
            <S.DescContainer ref={descBoxRef}>
                <Hr />
                <Text type="h3">상품 정보</Text>
                <Hr />
                <DescBox>상세 정보</DescBox>
            </S.DescContainer>
            <StickyPaymentBar
                product={product}
                count={count}
                height={heightValue}
            />
        </S.Wrapper>
    );
}

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
`;

const DescContainer = styled.div`
    grid-column: 1/4;

    & > h3 {
        margin: 24px 0;
        text-align: center;
    }
`;

const DescBox = styled.div`
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    margin-top: 24px;
    height: 1500px;
    background: ${({ theme }) => theme.COLOR.common.gray[600]};
    text-align: center;
`;

const S = {
    Wrapper,
    DescContainer,
};
