import styled from "@emotion/styled";
import { selectedStateAtom } from "Atom/selectedState.atom";
import Button from "Components/Button/Button";
import CheckBox from "Components/CheckBox/CheckBox";
import FormElement from "Components/FormElement/FormElement";
import Modal from "Components/Modal/Modal";
import SelectElement from "Components/SelectElement/SelectElement";
import SelectList from "Components/SelectElement/SelectList";
import Text from "Components/Text/Text";
import { FlexAlignStart } from "Styles/common";
import { selectMedia } from "Utils/selectList";
import axios from "axios";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

export default function SignUpStepSecond() {
    const location = useLocation();

    const searchParams = new URLSearchParams(window.location.href);
    const userType = searchParams.get("userType");

    const [signState, setSignState] = useState(null);
    const [selectedMedia, setSelectedMedia] = useRecoilState(selectedStateAtom);

    const { control, handleSubmit } = useForm({
        defaultValues: {
            email: "",
            interestMedia: "",
            ageOverAgree: "",
            sendAdsAgree: "",
        },
    });

    const onSubmitHandler = async (data) => {
        const { interestMedia, ageOverAgree, sendAdsAgree } = data;

        try {
            const res = await axios.post("/api/signup", {
                interestMedia,
                userType,
                ageOverAgree,
                sendAdsAgree,
            });

            const { message } = res?.data;
            setSignState(message);
        } catch (error) {
            setSignState(error?.response.data.message);
        }
    };

    return (
        <S.Wrapper>
            {signState && (
                <Modal
                    state={signState === "success" ? "positive" : "negative"}
                />
            )}
            <Text type="h1">
                회원정보를
                <br />
                입력하세요.
            </Text>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <S.FormContainer>
                    <FormElement
                        control={control}
                        name="email"
                        label="이메일 계정"
                    />
                    <Controller
                        name="interestMedia"
                        control={control}
                        rules={{ required: "옵션을 선택해주세요." }}
                        render={({ field, fieldState: { error: errors } }) => {
                            return (
                                <S.InterestMediaContainer>
                                    <p>관심사</p>
                                    <SelectElement
                                        size="large"
                                        path={location.pathname}
                                        field={field}
                                        error={errors?.message}
                                        onClick={() =>
                                            setSelectedMedia((prev) => !prev)
                                        }
                                    >
                                        {selectedMedia && (
                                            <SelectList
                                                size="large"
                                                field={field}
                                                list={selectMedia}
                                                setState={setSelectedMedia}
                                            />
                                        )}
                                    </SelectElement>
                                </S.InterestMediaContainer>
                            );
                        }}
                    />

                    <S.AgreeContainer>
                        <CheckBox
                            control={control}
                            name="ageOverAgree"
                            rules={{ required: "필수값" }}
                        >
                            [필수] 만 14세 이상이며 모두 동의합니다.
                        </CheckBox>
                        <CheckBox control={control} name="sendAdsAgree">
                            [선택] 광고성 정보 수신에 모두 동의합니다.
                        </CheckBox>
                    </S.AgreeContainer>
                </S.FormContainer>
                <Button styles="secondary" size="full">
                    가입하기
                </Button>
            </form>
        </S.Wrapper>
    );
}

const Wrapper = styled.section`
    width: 378px;
    margin: 0 auto;
`;

const FormContainer = styled.div`
    ${FlexAlignStart}
    flex-direction: column;
    row-gap: 32px;

    width: 100%;
    margin: 80px 0 48px 0;
`;

const InterestMediaContainer = styled.div`
    width: 100%;
    ${FlexAlignStart}
    flex-direction: column;
    row-gap: 8px;
`;

const AgreeContainer = styled.div`
    ${FlexAlignStart}
    flex-direction: column;
    row-gap: 8px;
`;

const S = {
    Wrapper,
    FormContainer,
    InterestMediaContainer,
    AgreeContainer,
};
