import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import { FlexAlignStart, FlexCenter, MarginAuto } from "Styles/common";

export const Wrapper = styled.section`
    ${MarginAuto}
`;

export const CompleteMsgBox = styled.div`
    ${FlexCenter}
    flex-direction: column;
    margin: 80px auto;
    text-align: center;
    gap: 48px;
`;

export const TextContainer = styled.div`
    ${FlexCenter}
    flex-direction: column;
    gap: 24px;
`;

export const Title = styled.div`
    & > h4 {
        margin-bottom: 16px;
    }
`;

export const Contents = styled.div`
    & > p:first-of-type {
        margin-bottom: 8px;
    }
`;

export const StyledButton = styled(Button)`
    width: 160px;
    height: 64px;
`;

export const PaidInfoBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    ${FlexAlignStart}
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    border: 1px solid ${({ theme }) => theme.COLOR.common.gray[500]};
    border-radius: 8px;
`;
