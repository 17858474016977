import countAtom from "Atom/countState.atom";
import { useRecoilState } from "recoil";

export default function useCountState() {
    const [count, setCount] = useRecoilState(countAtom);

    const onIncrease = () => {
        setCount((prev) => prev + 1);
    };

    const onDecrease = () => {
        setCount((prev) => {
            if (prev <= 1) return;
            return prev - 1;
        });
    };

    return { count, setCount, onIncrease, onDecrease };
}
