export const QUERY_KEY = {
    GET_MAIN_INTEREST_MEDIA: "GET_MAIN_INTEREST_MEDIA",
    GET_MAIN_HIGH_PAGEVIEW: "GET_MAIN_HIGH_PAGEVIEW",
    GET_MAIN_ALL_PRODUCT: "GET_MAIN_ALL_PRODUCT",

    GET_DETAIL_PRODUCT: "GET_DETAIL_PRODUCT",
    GET_DETAIL_STORE: "GET_DETAIL_STORE",
    GET_DETAIL_RELATED_PRODUCT: "GET_DETAIL_RELATED_PRODUCT",

    GET_SEARCH_PRODUCT: "GET_SEARCH_PRODUCT",

    GET_PAYMENT_COMPLETED: "GET_PAYMENT_COMPLETED",
};
