export const signMedia = [
    {
        카카오: {
            id: "1",
            img: "kakao_logo_vector.svg",
            url: "/sign-in/sign-in.php?sns=kakao",
            color: "#FEE500",
        },
    },
    {
        네이버: {
            id: "2",
            img: "naver_logo_vector.svg",
            url: "",
            color: "#03C75A",
        },
    },
    {
        구글: {
            id: "3",
            img: "google_logo_vector.svg",
            url: "",
            color: "",
        },
    },
];

export const companyFiles = [
    {
        id: "1",
        name: "이용약관",
        url: "",
    },
    {
        id: "2",
        name: "개인정보 처리방침",
        url: "",
    },
    {
        id: "3",
        name: "환불정책",
        url: "",
    },
    {
        id: "4",
        name: "사업자 정보 확인",
        url: "",
    },
];
