import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

export default function Logo() {
    const navigate = useNavigate();

    return (
        <S.Wrapper onClick={() => navigate("/")}>
            <img
                src={`${process.env.PUBLIC_URL}/assets/logo/symbol_character.svg`}
                alt="symbol_character"
            />
            <img
                src={`${process.env.PUBLIC_URL}/assets/logo/logo_lettering.svg`}
                alt="logo_lettering"
            />
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    cursor: pointer;
`;

const S = {
    Wrapper,
};
