import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY } from "Consts/Key/query.key";
import axios from "axios";

export const useGetCompletedPayment = (prod_idx) => {
    const { data, isLoading, error } = useQuery(
        [QUERY_KEY.GET_PAYMENT_COMPLETED],
        async () => {
            const res = await axios.get("/api/payment/completed", {
                params: prod_idx,
            });

            return res?.data;
        }
    );

    return { data, isLoading, error };
};
