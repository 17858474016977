import styled from "@emotion/styled";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import SearchAction from "./Components/SearchAction";

export default function SearchBar({ barState, setBarState }) {
    const searchBodyRef = useRef(null);
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        setBarState(false);
    }, [pathname]);

    useEffect(() => {
        setTimeout(() => {
            if (barState && searchBodyRef) {
                searchBodyRef.current.style.height = "320px";
            } else {
                searchBodyRef.current.style.height = "0px";
            }
        }, 50);
    }, [barState]);

    return (
        <S.Wrapper>
            <S.SearchContainer ref={searchBodyRef}>
                <SearchAction barState={barState} />
            </S.SearchContainer>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    margin-top: 80px;
    z-index: 0;
    top: 0;
    left: 0;
`;

const SearchContainer = styled.div`
    height: 0px;
    background: ${({ theme }) => theme.COLOR.common.white};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const S = {
    Wrapper,
    SearchContainer,
};
