const MEDIA = {
    mobile: "360px",
    tablet: "820px",
    laptop: "1440px",
    desktop: "1920px",
    devMove: "2560px",
};

const COLOR = {
    primary: {
        100: "#8ACE00", // default
        200: "#6EA500", // hover
        300: "#537C00", // pressed
        400: "#336600", // focused
        500: "#C6EB79", // disabled
    },

    common: {
        white: "#ffffff",
        black: {
            100: "#000000",
            200: "#3A3A3A",
        },
        gray: {
            100: "#666666",
            200: "#999999",
            300: "#aaaaaa",
            400: "#C9C9C9",
            500: "#e4e4e4",
            600: "#F7F7F7",
        },
    },

    response: {
        success: "#4bb543",
        error: "#ff6565",
        warning: "#f6bc2f",
    },

    state: {
    success:"#4bb543",
    error : "#ff6565"
    
    },
};

const theme = {
    MEDIA,
    COLOR,
};

export default theme;
