import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import Card from "Components/Card/Card";
import Text from "Components/Text/Text";
import { QUERY_KEY } from "Consts/Key/query.key";
import useInfinityScrolling from "Hooks/Queries/infinity/useInfinityScrolling";
import axios from "axios";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export default function MainTotal() {
    const [showMore, setShowMore] = useState(false);

    const getData = async (pageParams) => {
        try {
            const res = await axios.get(`/api/product`, {
                params: { pageNum: pageParams },
            });

            return res.data;
        } catch (error) {
            console.log(error);
        }
    };
    const { resultData, fetchNextPage, hasNextPage, isLoading } =
        useInfinityScrolling(QUERY_KEY.GET_MAIN_ALL_PRODUCT, getData);

    const onShowMore = () => {
        setShowMore(true);
    };

    useEffect(() => {
        if (resultData?.length > 8) setShowMore(true);
    }, []);

    return (
        resultData && (
            <S.Wrapper>
                <Text type="h3">애드몬스터 광고 상품</Text>
                <InfiniteScroll
                    style={{}}
                    dataLength={resultData?.length}
                    hasMore={showMore ? hasNextPage : false}
                    next={fetchNextPage}
                >
                    <Card type="B" data={resultData} display="grid" />
                    {!showMore && resultData?.length <= 8 && (
                        <Button
                            styles="tertiary"
                            size="full"
                            onClick={onShowMore}
                        >
                            더보기
                        </Button>
                    )}
                </InfiniteScroll>
            </S.Wrapper>
        )
    );
}

const Wrapper = styled.section`
    &::-webkit-scrollbar {
        display: none;
    }

    & > h3 {
        position: relative;
        margin-bottom: 24px;
        padding-left: 16px;
    }

    & > h3::after {
        content: "";
        position: absolute;
        left: 0;
        width: 8px;
        height: 100%;
        background-color: ${({ theme }) => theme.COLOR.common.black[100]};
        border: none;
    }
`;

const S = {
    Wrapper,
};
