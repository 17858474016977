import styled from "@emotion/styled";
import Input from "Components/Input/Input";
import { FlexAlignStart } from "Styles/common";
import { useController } from "react-hook-form";

export default function FormElement({ control, name, rules, label, ...rest }) {
    const {
        field,
        fieldState: { error },
    } = useController({
        control,
        name,
        rules,
    });
    const { value } = field;

    return (
        <S.Wrapper>
            <label htmlFor={name}>
                {label && <p>{label}</p>}
                <Input
                    name={name}
                    value={value}
                    size="large"
                    type={name.includes("password") ? "password" : "text"}
                    {...rest}
                    {...field}
                />
            </label>
            {error && <S.ErrorBox>{error?.message}</S.ErrorBox>}
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;

    & > label {
        ${FlexAlignStart}
        flex-direction: column;
        row-gap: 8px;
    }
`;

const ErrorBox = styled.p`
    position: absolute;
    z-index: 999;
    top: 50%;
    right: -64px;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.COLOR.state.error};
`;

const S = {
    Wrapper,
    ErrorBox,
};
