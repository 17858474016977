import { rest } from "msw";
import { productMockData } from "../ProductInfo/ProductMock";

const search_path = "/api/product/search";

export const SearchApi = rest.get(`${search_path}`, (req, res, ctx) => {
    const searchParams = new URLSearchParams(req.url.searchParams);

    const keyword = searchParams.get("keyword");
    const media = searchParams.get("media");

    const pageNum = parseInt(searchParams.get("pageNum"), 10);
    const startIndex = (pageNum - 1) * 16;
    const endIndex = startIndex + 16;

    const filterArr = productMockData.filter((it) => it.media === media);
    const searchArr = productMockData.filter((it) =>
        it.keyword.find((it) => it === keyword)
    );

    const filterResponseList = filterArr.slice(startIndex, endIndex);
    const searchResponseList = searchArr.slice(startIndex, endIndex);

    if (keyword) {
        if (media) {
            return res(
                ctx.status(200),
                ctx.json({
                    page: pageNum,
                    result: filterResponseList,
                    total: filterArr.length,
                })
            );
        }

        return res(
            ctx.status(200),
            ctx.json({
                page: pageNum,
                result: searchResponseList,
                total: searchArr.length,
            })
        );
    }
});
