import styled from "@emotion/styled";
import userAtom from "Atom/userInfo.atom";
import CardSlider from "Components/Slider/CardSlider";
import Text from "Components/Text/Text";
import { useGetMainPick } from "Hooks/Queries/main/getMainPick";
import { useRecoilValue } from "recoil";

export default function MainPick() {
    const globalUser = useRecoilValue(userAtom);
    const { data, isLoading, error } = useGetMainPick(globalUser);

    return (
        data && (
            <S.Wrapper>
                <Text type="h2">애드몬스터 PICK</Text>
                <CardSlider data={data} type="A" />
            </S.Wrapper>
        )
    );
}

const Wrapper = styled.section`
    position: relative;
`;

const S = {
    Wrapper,
};
