import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import Quantity from "Components/Quantity/Quantity";
import Text from "Components/Text/Text";
import { FlexBetween, FlexCenter, FlexJustifyBetween } from "Styles/common";
import { useNavigate } from "react-router-dom";

export function StickyPaymentBar({ product, count, height }) {
    const navigate = useNavigate();
    console.log(height);

    return (
        <S.BuyContainer height={height}>
            <S.BuyList>
                <div>
                    <Text type="h6">{product.title}</Text>
                    <S.CountingBox>
                        <Quantity />
                        <Text type="h5">
                            ￦
                            {Number(
                                product.price.result * count
                            ).toLocaleString()}
                        </Text>
                    </S.CountingBox>
                </div>
                <Button
                    onClick={() => {
                        navigate(`/payment/${product.idx}`, {
                            state: product,
                        });
                    }}
                    size="full"
                >
                    구매하기
                </Button>
            </S.BuyList>
        </S.BuyContainer>
    );
}

const BuyContainer = styled.div`
    position: relative;
    height: ${({ height }) => height && `${height}px`};
`;

const BuyList = styled.div`
    position: sticky;
    ${FlexJustifyBetween}
    flex-direction: column;
    top: 30px;
    width: 27.6rem;
    height: 20rem;
    border-radius: 8px;
    padding-top: 40px;
    background: ${({ theme }) => theme.COLOR.common.gray[600]};

    & > div:first-of-type {
        margin: 0 24px 0;
        display: flex;
        flex-direction: column;
        gap: 48px;
    }
`;

const CountingBox = styled.div`
    ${FlexBetween}

    & > div:first-of-type {
        display: flex;
        height: 24px;
        border: 1px solid ${({ theme }) => theme.COLOR.common.gray[300]};
    }
`;

const CountNum = styled.div`
    ${FlexCenter}
    width: 40px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.COLOR.common.white};
    border-right: 1px solid ${({ theme }) => theme.COLOR.common.gray[300]};
    border-left: 1px solid ${({ theme }) => theme.COLOR.common.gray[300]};
`;

const CountUpDown = styled.button`
    width: 24px;
    padding: 3px;
    border: none;
    background-color: ${({ theme }) => theme.COLOR.common.white};
    color: ${({ theme }) => theme.COLOR.common.gray[300]};
    cursor: pointer;
`;

const S = {
    BuyContainer,
    BuyList,
    CountingBox,
    CountNum,
    CountUpDown,
};
