import { css } from "@emotion/react";

export const FlexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FlexBetween = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FlexAlignStart = css`
    display: flex;
    align-items: flex-start;
`;

export const FlexAlignCenter = css`
    display: flex;
    align-items: center;
`;

export const FlexAlignEnd = css`
    display: flex;
    align-items: flex-end;
`;

export const FlexJustifyCenter = css`
    display: flex;
    justify-content: center;
`;

export const FlexJustifyBetween = css`
    display: flex;
    justify-content: space-between;
`;

export const FlexJustifyLeft = css`
    display: flex;
    justify-content: left;
`;

export const FlexJustifyRight = css`
    display: flex;
    justify-content: right;
`;

export const GridCenter = css`
    display: grid;
    align-items: center;
    justify-items: center;
`;

export const GridAlignCenter = css`
    display: grid;
    align-items: center;
`;

export const GridNumber = (matrix, num) => {
    if (matrix === "column") {
        return css`
            grid-template-columns: repeat(${num}, 1fr);
        `;
    }

    if (matrix === "row") {
        return css`
            grid-template-rows: repeat(${num}, 1fr);
        `;
    }
};

export const MarginAuto = css`
    width: 90%; // 이후 변경 예정
    max-width: 119.4rem;
    margin: 0 auto;
`;
