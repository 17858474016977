import Text from "Components/Text/Text";
import { useForm } from "react-hook-form";
import RegisterCategory from "./Components/RegisterCategory";
import RegisterDescription from "./Components/RegisterDescription";
import RegisterKeyword from "./Components/RegisterKeyword";
import RegisterPrice from "./Components/RegisterPrice";
import RegisterSoldState from "./Components/RegisterSoldState";
import RegisterSubTitle from "./Components/RegisterSubTitle";
import RegisterThumbnail from "./Components/RegisterThumbnail";
import RegisterTitle from "./Components/RegisterTitle";
import RegisterWorkDuration from "./Components/RegisterWorkDuration";
import * as S from "./ProductRegister.style";

export default function ProductRegister() {
    const { control, watch, setValue, getValues, handleSubmit } = useForm({
        defaultValues: {
            product_title: "",
            product_subtitle: "",
            thumbnail: undefined,
            product_description: "",
            normal: "",
            discount: "",
            min: "",
            max: "",
            category: "",
            keywords: [],
            sold_state: true,
        },
    });

    return (
        <S.Wrapper>
            <Text type="h5" subtype="p">
                상품등록
            </Text>
            <form>
                <S.ProductRegisterContainer>
                    <RegisterTitle control={control} watch={watch} />
                    <RegisterSubTitle control={control} watch={watch} />
                    <RegisterThumbnail
                        control={control}
                        setValue={setValue}
                        watch={watch}
                    />
                    <RegisterDescription control={control} />
                    <RegisterPrice control={control} />
                    <RegisterWorkDuration control={control} />
                    <RegisterCategory control={control} setValue={setValue} />
                    <RegisterKeyword
                        control={control}
                        watch={watch}
                        setValue={setValue}
                    />
                    <RegisterSoldState control={control} />
                </S.ProductRegisterContainer>
            </form>
        </S.Wrapper>
    );
}
