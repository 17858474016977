import styled from "@emotion/styled";
import {
    FlexAlignCenter,
    FlexAlignStart,
    MarginAuto,
} from "../../../Styles/common";

export const Wrapper = styled.footer`
    margin-top: 160px;
    background: ${({ theme }) => theme.COLOR.common.white};
`;

export const Body = styled.div`
    ${FlexAlignStart}
    ${MarginAuto}
    

    column-gap: 32px;
    padding: 80px 0;
`;

export const ComponyContainer = styled.div`
    ${FlexAlignStart}
    flex-direction: column;
    row-gap: 24px;

    & > div:last-of-type {
        color: ${({ theme }) => theme.COLOR.common.gray[400]};
    }

    & p {
        line-height: 150%;
    }
`;

export const CatchPraiseContainer = styled.div`
    p {
        margin-top: 8px;
    }
`;

export const ComponyInfoList = styled.div`
    ${FlexAlignCenter}
    column-gap: 16px;
    margin-bottom: 8px;
    font-size: 14px;

    & div {
        position: relative;
        line-height: 150%;
    }

    & div::after {
        content: "·";
        position: absolute;
        top: 0;
        right: -12px;
        margin-left: 16px;
    }

    & > a {
        color: ${({ theme }) => theme.COLOR.common.gray[400]};
        line-height: 150%;
    }

    & > a:last-of-type div::after {
        display: none;
    }
`;

export const PartnerContainer = styled.div`
    ${FlexAlignStart}
    flex-direction: column;
    row-gap: 8px;

    & p {
        font-size: 1.4rem;
        color: ${({ theme }) => theme.COLOR.common.gray[400]};
        line-height: 150%;
    }

    & > div:first-of-type > p {
        margin-top: 8px;
        color: ${({ theme }) => theme.COLOR.common.black[100]};
    }

    & > div:nth-of-type(2) {
        ${FlexAlignStart}
        column-gap: 8px;
    }
`;
