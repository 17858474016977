import FormElement from "Components/FormElement/FormElement";
import Text from "Components/Text/Text";
import * as S from "./RegisterOption.style";

export default function RegisterTitle({ control, watch }) {
    const productTitle = watch("product_title");

    const maxLength = 30;
    const length = productTitle.length;

    return (
        <S.Wrapper>
            <Text type="h6" subType="p">
                상품명
            </Text>
            <S.InputContainer>
                <FormElement
                    control={control}
                    name="product_title"
                    color="none"
                    placeholder="상품명을 입력하세요."
                    maxLength={maxLength}
                />
                <S.TypoLength>
                    <Text type="h6" subType="p">
                        {length}/{maxLength}
                    </Text>
                </S.TypoLength>
            </S.InputContainer>
        </S.Wrapper>
    );
}
