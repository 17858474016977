import { rest } from "msw";
import { productMockData } from "./ProductMock";
import { ProductResponse } from "./ProductResData";

const product_path = "/api/product";

export const ProductCreate = rest.post(`${product_path}`, (req, res, ctx) => {
    const { media, category, soldState, title, description, thumbnail, price } =
        req.body;

    return res(ctx.status(200), ctx.json(ProductResponse.create(200)));
});

export const ProductRead = rest.get(`${product_path}`, (req, res, ctx) => {
    // pageView도 파라미터로 받아야함

    const searchParams = new URLSearchParams(req.url.searchParams);
    const media = searchParams.get("media");
    const pageNum = searchParams.get("pageNum");
    const productIdx = searchParams.get("productIdx");
    const pageView = searchParams.get("pageView");
    const relation = searchParams.get("relation");

    if (relation) {
        const relationList = productMockData.filter(
            (item) => item.category === relation
        );

        return res(ctx.status(200), ctx.json(relationList));
    }
    if (pageView) {
        const pageViewList = productMockData
            .slice()
            .sort((a, b) => parseInt(b.pageView) - parseInt(a.pageView));

        return res(ctx.status(200), ctx.json(pageViewList));
    }

    // 관심사 데이터 조회
    if (media) {
        const interestMedia = productMockData.filter(
            (it) => it.media === media
        );

        return res(
            ctx.status(200),
            ctx.json(ProductResponse.read(200, interestMedia))
        );
    }
    if (pageNum) {
        const page = parseInt(req.url.searchParams.get("pageNum"), 10) || 1;

        const startIndex = (page - 1) * 8;
        const endIndex = startIndex + 8;
        const data = productMockData.slice(startIndex, endIndex);

        return res(
            ctx.status(200),
            ctx.json({
                page: page,
                result: data,
                total_result: productMockData.length,
                total_pages: Math.ceil(productMockData.length / 8),
            })
        );
    }

    if (productIdx) {
        const productDetail = productMockData.find(
            (it) => parseInt(it.idx) === parseInt(productIdx)
        );

        return res(ctx.status(200), ctx.json(productDetail));
    }
    return res(ctx.status(200), ctx.json(ProductResponse.read(200)));
});

export const ProductUpdate = rest.patch(`${product_path}`, (req, res, ctx) => {
    const { media, category, soldState, title, description, thumbnail, price } =
        req.body;

    return res(ctx.status(200), ctx.json(ProductResponse.update(200)));
});

export const ProductDelete = rest.delete(`${product_path}`, (req, res, ctx) => {
    const { idx } = req.params;
    const deleteProduct = productMockData.filter((it) => it.idx === idx);

    if (deleteProduct) {
        return res(
            ctx.status(200),
            ctx.json(ProductResponse.delete(200, deleteProduct.idx))
        );
    }
});
