export const selectMedia = [
    {
        id: 1,
        media: "인스타그램",
    },
    {
        id: 2,
        media: "네이버",
    },
    {
        id: 3,
        media: "유튜브",
    },
    {
        id: 4,
        media: "카카오",
    },
];

export const selectState = [
    {
        success: "작업완료",
    },
    {
        prepare: "준비중",
    },
    {
        complete: "작업완료",
    },
];
