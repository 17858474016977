import styled from "@emotion/styled";
import CardSlider from "Components/Slider/CardSlider";
import Text from "Components/Text/Text";
import { useGetMainPageView } from "Hooks/Queries/main/getMainPageView";

export default function MainPageView() {
    const { data, isLoading, error } = useGetMainPageView();

    return (
        data && (
            <S.Wrapper>
                <Text type="h2">페이지 조회가 높은 상품</Text>
                <CardSlider data={data} type="A" />
            </S.Wrapper>
        )
    );
}

const Wrapper = styled.section``;

const S = {
    Wrapper,
};
