export const payment = [
    {
        id: 1,
        value: "creditcard",
        name: "신용 카드",
        img: "credit_vector.svg",
    },
    {
        id: 2,
        value: "kakaopay",
        name: "카카오 페이",
        img: "kakao_logo_vector.svg",
    },
    {
        id: 3,
        value: "naverpay",
        name: "네이버 페이",
        img: "naver_logo_vector.svg",
    },
];
