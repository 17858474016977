import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import CheckBox from "Components/CheckBox/CheckBox";
import Hr from "Components/Hr/Hr";
import Text from "Components/Text/Text";
import { FlexAlignStart, FlexCenter, FlexJustifyBetween } from "Styles/common";
import theme from "Styles/theme";

export default function PaidSubmit({ product, count, control }) {
    return (
        <S.Wrapper>
            <S.Body>
                <S.PriceContainer>
                    <S.DetailPriceInfoBox>
                        <S.DetailPrice>
                            <Text type="h5">정상가</Text>
                            <Text type="h5">
                                {Number(
                                    product.price.normal * count
                                ).toLocaleString()}
                            </Text>
                        </S.DetailPrice>
                        <S.DetailPrice>
                            <Text type="h5" subType="p">
                                할인 금액
                            </Text>
                            <Text type="h5" subType="p">
                                -{" "}
                                {Number(
                                    product.price.discount * count
                                ).toLocaleString()}
                            </Text>
                        </S.DetailPrice>
                    </S.DetailPriceInfoBox>

                    <Hr margin="search" />

                    <ResultPrice>
                        <Text type="h4">결제 금액</Text>
                        <Text type="h4">
                            {Number(
                                product.price.result * count
                            ).toLocaleString()}
                        </Text>
                    </ResultPrice>
                </S.PriceContainer>

                <S.AgreeContainer>
                    <CheckBox
                        control={control}
                        name="pay_serviceAgree"
                        rules={{ required: "필수값" }}
                    >
                        서비스 이용약관 전체 동의 (필수)
                    </CheckBox>
                    <CheckBox
                        control={control}
                        name="pay_overAgeAgree"
                        rules={{ required: "필수값" }}
                    >
                        만 14세 이상입니다. (필수)
                    </CheckBox>
                    <CheckBox
                        control={control}
                        name="pay_privateInfoAgree"
                        rules={{ required: "필수값" }}
                    >
                        개인 정보 수집, 이용 동의 (필수)
                    </CheckBox>
                    <CheckBox control={control} name="pay_marketingAgree">
                        마케팅 수신, 홍보 목적의 개인 정보 수집 및 이용 동의
                        (선택)
                    </CheckBox>
                </S.AgreeContainer>
            </S.Body>

            <Button styles="secondary" size="full" type="submit">
                결제하기
            </Button>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    ${FlexJustifyBetween}
    flex-direction: column;
    align-self: baseline;
    height: 405px;
    border: 1px solid ${theme.COLOR.common.gray[500]};
    border-radius: 8px;
`;

const Body = styled.div`
    ${FlexJustifyBetween}
    flex-direction: column;
    gap: 48px;
    padding: 32px;
`;

const PriceContainer = styled.div``;

const DetailPriceInfoBox = styled.div`
    ${FlexCenter}
    flex-direction: column;
    row-gap: 8px;
`;

const DetailPrice = styled.div`
    width: 100%;
    ${FlexJustifyBetween}
`;

const ResultPrice = styled.div`
    ${FlexJustifyBetween}

    & > h4:last-of-type {
        color: #ff3e3e;
    }
`;

const AgreeContainer = styled.div`
    ${FlexAlignStart}
    flex-direction: column;
    gap: 16px;
`;

const S = {
    Wrapper,
    Body,
    PriceContainer,
    DetailPriceInfoBox,
    DetailPrice,
    AgreeContainer,
};
