import Text from "Components/Text/Text";
import { signMedia } from "Utils/etcList";
import { Link } from "react-router-dom";
import * as S from "../Styles/Sign.style";

export default function SignUp() {
    return (
        <S.Wrapper>
            <Text type="h1">
                간편하게
                <br />
                회원가입하세요.
            </Text>
            <S.SignInMediaContainer>
                {signMedia.map((media) => {
                    return (
                        <Link
                            to={`${process.env.REACT_APP_BACKEND_URL}${
                                Object.values(media)[0].url
                            }`}
                            key={Object.values(media)[0].id}
                        >
                            <S.StyledButton
                                size="full"
                                styles="tertiary"
                                media={Object.keys(media)[0]}
                                style={{
                                    backgroundColor:
                                        Object.values(media)[0].color,
                                    boxShadow:
                                        Object.keys(media)[0] === "구글" &&
                                        "0 0 4px rgba(0,0,0,0.1)",
                                }}
                            >
                                <S.MediaSymbol
                                    backDrop_img={`${
                                        process.env.PUBLIC_URL
                                    }/assets/media_symbol/${
                                        Object.values(media)[0].img
                                    }`}
                                />
                                <Text type="h6">
                                    {Object.keys(media)} 간편 가입
                                </Text>
                            </S.StyledButton>
                        </Link>
                    );
                })}
            </S.SignInMediaContainer>
        </S.Wrapper>
    );
}
