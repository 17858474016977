import Button from "Components/Button/Button";
import Hr from "Components/Hr/Hr";
import Text from "Components/Text/Text";
import { companyFiles } from "Utils/etcList";
import { Link } from "react-router-dom";
import Logo from "../Components/Logo";
import * as S from "./Footer.style";

export default function Footer() {
    return (
        <S.Wrapper>
            <Hr />
            <S.Body>
                <S.ComponyContainer>
                    <S.CatchPraiseContainer>
                        <Logo />
                        <p>
                            에드몬스터는 최고의 전문가로 이루어져 최상의
                            마케팅을 제안해 드립니다.
                        </p>
                    </S.CatchPraiseContainer>
                    <div>
                        <S.ComponyInfoList>
                            {companyFiles.map((file) => {
                                return (
                                    <Link to={file.url} key={file.id}>
                                        <div key={file.id}>{file.name}</div>
                                    </Link>
                                );
                            })}
                        </S.ComponyInfoList>
                        <p>
                            대표 빈태찬 | 서울특별시 강남구 테헤란로 151,
                            패스트파이브 511호 | 사업자등록번호 : 826-86-02651 |
                            통신판매업신고 : 0000-0000-0000
                        </p>
                        <p>
                            애드몬스터는 전자상거래 등에서의 소비자보호에관한
                            법률에 따른 통신판매업과 통신판매중개업을 영위하고
                            있습니다. 애드몬스터 통신판매중개자로서 중개하는
                            통신판매에 관하여서는 통신판매의 당사자가 아니므로
                            어떠한 책임도 부담하지 아니합니다.
                        </p>
                    </div>
                </S.ComponyContainer>
                <S.PartnerContainer>
                    <div>
                        <Text type="h3">고객센터</Text>
                        <p>오전 10:00 ~ 오후 17:00 (주말, 공휴일 제외)</p>
                    </div>
                    <div>
                        <Button styles="tertiary">문의하기</Button>
                        <div>
                            <p>1661-5627</p>
                            <p>help@admonster.kr</p>
                        </div>
                    </div>
                    <p>
                        애드몬스터는 서비스 중개 플랫폼이에요. 작업 의뢰는
                        전문가에게 직접 문의해 주세요.
                    </p>
                </S.PartnerContainer>
            </S.Body>
        </S.Wrapper>
    );
}
