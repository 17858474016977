import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY } from "../../../Consts/Key/query.key";

export const useGetMainPick = (globalUsers) => {
    const { data, isLoading, error } = useQuery(
        [QUERY_KEY.GET_MAIN_INTEREST_MEDIA],
        async () => {
            try {
                const res = await axios.get("/api/product", {
                    params: { media: globalUsers?.interestMedia },
                });

                return res.data;
            } catch (error) {
                console.log(error);
            }
        },
        {
            staleTime: "",
            cacheTime: "",
        }
    );

    return { data, isLoading, error };
};
