import styled from "@emotion/styled";
import CardSlider from "Components/Slider/CardSlider";
import Text from "Components/Text/Text";
import { useGetDetailRelation } from "Hooks/Queries/detail/getDetailRelation";

export default function RelatedProduct(props) {
    const { category } = props;
    const { data, isLoading, error } = useGetDetailRelation(category);

    return (
        <S.Wrapper>
            <Text type="h3">연관 상품</Text>
            <CardSlider data={data} type="B" />
        </S.Wrapper>
    );
}

const Wrapper = styled.section`
    overflow: hidden;
    & > h3 {
        margin-bottom: 24px;
    }
`;

const S = {
    Wrapper,
};
