import { css } from "@emotion/react";
import styled from "@emotion/styled";
import theme from "../../Styles/theme";

const sizeCSS = {
    default: css`
        font-size: 20px;
    `,

    small: css`
        font-size: 18px;
    `,

    medium: css`
        font-size: 24px;
    `,

    large: css`
        font-size: 32px;
    `,

    modal: css`
        font-size: 32px;
        padding: 10px;
    `,
};

const colorCSS = {
    default: css`
        color: ${theme.COLOR.common.black};
    `,

    common: css`
        color: ${theme.COLOR.common.gray[300]};
    `,

    positive: css`
        color: ${theme.COLOR.response.success};
        border: 3px solid ${theme.COLOR.response.success};
    `,

    negative: css`
        color: ${theme.COLOR.response.error};
        border: 3px solid ${theme.COLOR.response.error};
    `,

    caution: css`
        color: ${theme.COLOR.response.warning};
        border: 3px solid ${theme.COLOR.response.warning};
    `,
};

const shapeCSS = {
    default: css`
        border-radius: 50%;
    `,
};

export const IconEvent = styled.span`
    ${({ size = "default" }) => sizeCSS[size]}
    ${({ color = "default" }) => colorCSS[color]}
    ${({ shape = "default" }) => shapeCSS[shape]}
    cursor: pointer;
`;
