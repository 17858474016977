export const userManagerKey = {
    userInfoAtom: "user_info",
};

export const searchBarKey = {
    searBarState: "search_bar",
};

export const selectedBoxKey = {
    selectedState: "selected_box",
};

export const countProductKey = {
    countState: "count_product",
};
