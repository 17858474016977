import styled from "@emotion/styled";
import Button from "../Button/Button";

export const SlideContainer = styled.div`
    position: relative;
`;

export const StyledButton = styled(Button)`
    position: absolute;
    top: ${({ type }) => (type === "A" ? "50%" : "31%")};
    transform: translateY(-50%);
    border-radius: 50%;
    color: ${({ theme }) => theme.COLOR.common.black};
    background: ${({ theme }) => theme.COLOR.common.gray[400]};

    &:first-of-type {
        left: 19px;
    }

    &:last-of-type {
        right: 19px;
    }
`;
