import styled from "@emotion/styled";
import Input from "Components/Input/Input";
import { FlexAlignCenter } from "Styles/common";
import { useController } from "react-hook-form";

export default function RadioBox({ control, name, options, rules }) {
    const {
        field,
        fieldState: { error },
    } = useController({ control, name, rules });

    return (
        <S.Wrapper>
            {options.map((option) => (
                <label htmlFor={option.id} key={option.id}>
                    <Input
                        name={name}
                        id={option.id}
                        type="radio"
                        size="check"
                        {...field}
                        value={option.value}
                        defaultChecked={option.id === 1}
                    />
                    <p id="name">{option.name}</p>
                </label>
            ))}
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    gap: 30px;
    & > label {
        ${FlexAlignCenter}
        &:hover {
            cursor: pointer;
        }
        & p {
            margin-left: 8px;
        }
        & > div {
            width: 18px;
            height: 18px;
            background: ${({ url }) =>
                url
                    ? `url(${process.env.PUBLIC_URL}/assets/media_symbol/${url})no-repeat center center`
                    : null};
        }
    }
`;

const S = {
    Wrapper,
};
