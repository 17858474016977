import styled from "@emotion/styled";
import Quantity from "Components/Quantity/Quantity";
import Text from "Components/Text/Text";
import { FlexBetween, FlexJustifyBetween } from "Styles/common";

export default function PaidProduct({ product, count }) {
    return (
        <S.Wrapper>
            <Thumbnail url={product.thumbnail} />
            <S.TextContainer>
                <TitleText>
                    <Text type="h5">{product.title}</Text>
                    <Text type="h5" subType="p">
                        {product.description}
                    </Text>
                </TitleText>
                {count ? (
                    <S.Option count={count}>
                        <S.StyledQuantity />
                        <Text type="h5">
                            {Number(
                                product.price.result * count
                            ).toLocaleString()}
                            원
                        </Text>
                    </S.Option>
                ) : (
                    <S.Option count={count}>
                        <Text type="h5">
                            {Number(product.price.result).toLocaleString()}원
                        </Text>
                    </S.Option>
                )}
            </S.TextContainer>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-self: baseline;
    column-gap: 24px;
`;

const Thumbnail = styled.div`
    width: 27.6rem;
    height: 17.6rem;
    background: ${({ url }) => `url(${url})no-repeat center center`};
    background-size: cover;
    border-radius: 8px;
    box-sizing: border-box;
    flex-shrink: 0;
`;

const TextContainer = styled.div`
    width: 100%;
    padding: 16px;
    ${FlexJustifyBetween}
    flex-direction: column;
`;

const StyledQuantity = styled(Quantity)`
    right: 0;
`;

const TitleText = styled.div`
    & > h5:first-of-type {
        margin-bottom: 20px;
    }
`;

const Option = styled.div`
    width: 100%;
    ${FlexBetween}
    justify-content: ${({ count }) => (!count ? "flex-end" : "space-between")};
    gap: 16px;

    & > h5:first-of-type {
        margin: ${({ count }) => (!count ? "0" : "0 16px")};
    }
`;

const S = {
    Wrapper,
    TextContainer,
    StyledQuantity,
    Option,
};
