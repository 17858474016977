import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import FormElement from "Components/FormElement/FormElement";
import Text from "Components/Text/Text";
import { FlexAlignCenter, FlexBetween } from "Styles/common";
import * as S from "./RegisterOption.style";

export default function RegisterKeyword({ control, watch, setValue }) {
    const inputKeyword = watch("inputKeyword", "");
    const keywords = watch("keywords");
    const maxLength = 30;
    const length = inputKeyword.length;
    const maxKeyWords = 5; // 최대 키워드 개수
    const onClickAddKeyWord = () => {
        if (inputKeyword.trim() !== "" && keywords.length < maxKeyWords) {
            setValue("keywords", [...keywords, inputKeyword.trim()]);
            setValue("inputKeyword", ""); // 입력 필드 초기화
        }
    };
    const onPressEnterAddKeyWord = (e) => {
        if (e.keyCode === 13 || e.key === "Enter") {
            onClickAddKeyWord();
            return;
        }
    };
    const removeKeyword = (indexToRemove) => {
        const updatedKeywords = keywords.filter(
            (_, index) => index !== indexToRemove
        );
        setValue("keywords", updatedKeywords);
    };
    return (
        <S.Wrapper align="flex-start">
            <Text type="h6" subType="p">
                검색키워드
            </Text>
            <S.InputContainer display="flex" direction="column">
                <InputKeyWordList>
                    <div onKeyDown={onPressEnterAddKeyWord}>
                        <FormElement
                            control={control}
                            name="inputKeyword"
                            placeholder="키워드를 입력하세요."
                            color="none"
                            maxLength={maxLength}
                        />

                        <S.TypoLength>
                            <Text type="h6" subType="p">
                                {length} /{maxLength}
                            </Text>
                        </S.TypoLength>
                    </div>
                    <Button
                        styles="tertiary"
                        size="small"
                        onClick={onClickAddKeyWord}
                        disabled={keywords >= maxKeyWords}
                    >
                        추가
                    </Button>
                </InputKeyWordList>
                <KeyWordList>
                    {keywords.map((keyword, index) => (
                        <KeyWordBox key={index}>
                            <Text type="h6" subType="p">
                                {keyword}
                            </Text>
                            <div onClick={() => removeKeyword(index)}>
                                {" "}
                                <Text type="h6" subType="p">
                                    X
                                </Text>
                            </div>
                        </KeyWordBox>
                    ))}
                </KeyWordList>
            </S.InputContainer>
        </S.Wrapper>
    );
}

const InputKeyWordList = styled.div`
    position: relative;
    width: 100%;
    ${FlexBetween}
    & > div:first-of-type {
        position: relative;
        width: 100%;
    }
`;
const KeyWordList = styled.div`
    ${FlexAlignCenter}
    width: 100%;

    gap: 8px;
    padding-left: 8px;
`;
const KeyWordBox = styled.div`
    ${FlexAlignCenter}
    gap: 8px;
    padding: 4px;
    background: ${({ theme }) => theme.COLOR.common.gray[400]};
    border-radius: 8px;
    color: ${({ theme }) => theme.COLOR.common.white};

    & > div:last-of-type {
        ${FlexAlignCenter}
        width: 16px;
        height: 16px;

        &:hover {
            cursor: pointer;
        }
    }
`;
