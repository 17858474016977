import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY } from "../../../Consts/Key/query.key";

export const useGetMainPageView = () => {
    const { data, isLoading, error } = useQuery(
        [QUERY_KEY.GET_MAIN_HIGH_PAGEVIEW],
        async () => {
            try {
                const res = await axios.get("/api/product", {
                    params: {
                        pageView: true,
                    },
                });
                return res.data;
            } catch (error) {
                console.log(error);
            }
        },
        {}
    );

    return { data, isLoading, error };
};
