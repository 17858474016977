import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY } from "../../../Consts/Key/query.key";

export const useGetDetailRelation = (category) => {
    const { data, isLoading, error } = useQuery(
        [QUERY_KEY.GET_DETAIL_RELATED_PRODUCT],
        async () => {
            try {
                const res = await axios.get("/api/product", {
                    params: {
                        relation: category,
                    },
                });

                return res.data;
            } catch (error) {
                console.log(error);
            }
        }
    );

    return { data, isLoading, error };
};
