import { atom } from "recoil";
import { userManagerKey } from "../Consts/Key/atom.key";
import { UserInfoManager } from "../Utils/userInfoManager";

const userAtom = atom({
    key: userManagerKey.userInfoAtom,
    default: UserInfoManager.getUser() ?? null,
});

export default userAtom;
