import { useInfiniteQuery } from "@tanstack/react-query";
import { selectedStateAtom } from "Atom/selectedState.atom";
import Card from "Components/Card/Card";
import Hr from "Components/Hr/Hr";
import SelectElement from "Components/SelectElement/SelectElement";
import SelectList from "Components/SelectElement/SelectList";
import Text from "Components/Text/Text";
import { QUERY_KEY } from "Consts/Key/query.key";
import { selectMedia } from "Utils/selectList";
import axios from "axios";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import RelatedKeyword from "./Components/RelatedKeyword";
import * as S from "./SearchResult.style";

export default function SearchResult() {
    const params = useParams();
    const location = useLocation();

    const [isFilter, setIsFilter] = useState({ media: "" });
    const [selectedMedia, setSelectedMedia] = useRecoilState(selectedStateAtom);

    const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
        queryKey: [
            QUERY_KEY.GET_SEARCH_PRODUCT,
            params.keyword,
            isFilter.media,
        ],
        queryFn: async ({ pageParam = 1 }) => {
            const res = await axios.get("/api/product/search", {
                params: {
                    keyword: params.keyword,
                    media: isFilter.media,
                    pageNum: pageParam,
                },
            });

            return res?.data;
        },
        getNextPageParam: (lastPage, pages) => {
            const nextPage = pages?.length + 1;
            return lastPage.result?.length === 0 ? undefined : nextPage;
        },
    });

    const resultDoubleArr = data?.pages.map((it) => it.result);
    const resultData = resultDoubleArr?.flat();

    const onFilterApi = async (e) => {
        e.preventDefault();
        const { innerText } = e.target;

        setIsFilter({
            media: innerText,
        });
    };

    if (isLoading) <div>...로딩중</div>;

    return (
        data && (
            <S.Wrapper>
                <Text type="h4">'{params.keyword}'의 검색결과</Text>
                <RelatedKeyword />
                <Hr margin="search" />
                <SelectElement
                    path={location.pathname}
                    onClick={() => setSelectedMedia((prev) => !prev)}
                    media={isFilter?.media}
                >
                    {selectedMedia && (
                        <SelectList
                            list={selectMedia}
                            setState={setSelectedMedia}
                            onClick={onFilterApi}
                        />
                    )}
                </SelectElement>
                <S.SearchResultNum>
                    검색결과 {data?.pages[0].total}개
                </S.SearchResultNum>
                <InfiniteScroll
                    dataLength={resultData?.length}
                    hasMore={hasNextPage}
                    next={fetchNextPage}
                >
                    <Card type="B" data={resultData} display="grid" />
                </InfiniteScroll>
            </S.Wrapper>
        )
    );
}
