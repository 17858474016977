import styled from "@emotion/styled";
import { MarginAuto } from "Styles/common";
import MainBanner from "./Components/MainBanner";
import MainPageView from "./Components/MainPageView";
import MainPick from "./Components/MainPick";
import MainTotal from "./Components/MainTotal";

export default function Main() {
    return (
        <S.Wrapper>
            <MainBanner />
            <MainPick />
            <MainPageView />
            <MainTotal />
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    padding-top: 80px;

    & > :where(section:nth-of-type(1), section:nth-of-type(2)) {
        overflow: hidden;

        & > h2 {
            text-align: center;
            margin-bottom: 48px;
        }
    }

    & > section {
        ${MarginAuto}
    }

    & > section h2 {
        margin-bottom: 48px;
    }
`;

const S = {
    Wrapper,
};
