import { useLocation } from "react-router-dom";
import * as S from "./SelectElement.style";

export default function SelectList({
    list,
    field,
    setState,
    onClick,
    ...restProps
}) {
    const location = useLocation();

    return (
        <>
            {list.map((it) => {
                return (
                    <S.SelectList
                        key={it.id}
                        onClick={(e) => {
                            field?.onChange(it.media);
                            setState(false);

                            if (
                                location.pathname.includes("signup") === false
                            ) {
                                onClick(e);
                            }
                        }}
                        {...restProps}
                    >
                        {it.media}
                    </S.SelectList>
                );
            })}
        </>
    );
}
