import styled from "@emotion/styled";

export default function MainBanner() {
    return <S.Wrapper></S.Wrapper>;
}

const Wrapper = styled.div`
    height: 376px;
    background: ${({ theme }) => theme.COLOR.common.gray[600]};
`;

const S = {
    Wrapper,
};
