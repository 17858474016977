const Today = new Date();
const currentYear = Today.getFullYear();
const currentMonth = Today.getMonth() + 1;
const currentDate = Today.getDate();

// data_base : mock
export const UserMockData = [
  {
    // buyer account
    email: "been.bin@gmail.com",
    password: "1111",
    interestMedia: "",
    profileUrl: "",
    store: {
      idx: "55554444",
      name: "",
      address: "",
      tel: "",
      profileUrl: "",
    },
    tokenForHeader: "",
  },
  {
    // buyer account
    email: "jacob.yoon@gmail.com",
    password: "2222",
    interestMedia: "",
    profileUrl: "",
    store: {
      idx: "77776666",
      name: "",
      address: "",
      tel: "",
      profileUrl: "",
    },
    tokenForHeader: "",
  },
  {
    // buyer account
    email: "jin.lee@gmail.com",
    password: "3333",
    interestMedia: "",
    profileUrl: "",
    store: {
      idx: "99998888",
      name: "",
      address: "",
      tel: "",
      profileUrl: "",
    },
    tokenForHeader: "",
  },

  {
    //agency account
    email: "agency@gmail.com",
    password: "4444",

    store: {
      // 업장
      idx: Math.floor(Math.random() * 100000),
      name: "레드브릭스", //상호명
      address: "", // 주소
      tel: "", // 전화번호
      business: "", // 사업자
      profileUrl: "", //
      products: [
        {
          idx: Math.floor(Math.random() * 100000),
          media: "인스타그램",
          category: "인플루언서",
          soldState: "판매중",
          title: "효과 검증된 인플루언서 체험단",
          description: "매출 영향 바로 직결",
          keyword: ["인스타", "인플루언서", "체험단"],
          pageView: 10,
          date: `${currentYear}년 ${currentMonth}월 ${currentDate}일`,
          thumbnail: `${process.env.PUBLIC_URL}/assets/mock_img/instagram_001.jpg`,
          price: {
            normal: 100000,
            discount: 20000,
            result: 80000,
          },
        },
        {
          idx: Math.floor(Math.random() * 100000),
          media: "유튜브",
          category: "유튜브 동영상 광고",
          soldState: "판매중",
          title: "사람들이 자주보는 유튜브 광고",
          description: "유튜브에서 많이 보이면 검색량이 높아져요",
          keyword: ["유튜브", "인플루언서", "체험단"],
          pageView: 14,
          date: `${currentYear}년 ${currentMonth - 1}월 ${currentDate}일`,
          thumbnail: `${process.env.PUBLIC_URL}/assets/mock_img/youtube_001.jpg`,
          price: {
            normal: 100000,
            discount: 20000,
            result: 80000,
          },
        },
        {
          idx: Math.floor(Math.random() * 100000),
          media: "네이버",
          category: "파워링크",
          soldState: "판매중",
          title: "검색어 상위 노출 광고",
          description: "제일 잘 나가는 키워드만 씁니다.",
          keyword: ["네이버", "인플루언서", "체험단"],
          pageView: 15,
          date: `${currentYear}년 ${currentMonth}월 ${currentDate - 2}일`,
          thumbnail: `${process.env.PUBLIC_URL}/assets/mock_img/naver_001.jpg`,
          price: {
            normal: 100000,
            discount: 20000,
            result: 80000,
          },
        },
        {
          idx: Math.floor(Math.random() * 100000),
          media: "카카오",
          category: "비즈보드 광고",
          soldState: "판매중",
          title: "채팅방 상단 광고, 타겟팅 광고",
          description: "매일 사용하는 카카오톡에서 내 상품이 보여요",
          keyword: ["카카오", "인플루언서", "체험단"],
          pageView: 17,
          date: `${currentYear}년 ${currentMonth}월 ${currentDate - 1}일`,
          thumbnail: `${process.env.PUBLIC_URL}/assets/mock_img/kakao_001.jpg`,
          price: {
            normal: 100000,
            discount: 20000,
            result: 80000,
          },
        },
        {
          idx: Math.floor(Math.random() * 100000),
          media: "테스트",
          category: "베타 테스터 광고",
          soldState: "판매중",
          title: "테스트가 제일 좋아, 테스트 광고",
          description: "테스트 테스트 테스트 입니다.",
          keyword: ["테스트", "인플루언서", "체험단"],
          pageView: 663,
          date: `${currentYear}년 ${currentMonth}월 ${currentDate - 1}일`,
          thumbnail: `${process.env.PUBLIC_URL}/assets/mock_img/kakao_001.jpg`,
          price: {
            normal: 100000,
            discount: 20000,
            result: 80000,
          },
        },
      ],
    },
    tokenForHeader: "",
  },
];
