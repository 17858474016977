import { rest } from "msw";
import { UserMockData } from "./UserMock";
import { LoginResponse, SignUpResponse } from "./UserResData";

const signUp_path = "/api/signup";
const login_path = "/api/login";

export const signUpApi = rest.post(`${signUp_path}`, (req, res, ctx) => {
    const { email, password, interestMedia, userType } = req.body;

    const findUser = UserMockData.find((user) => user.email === email);

    // 중복 유저 발견
    if (findUser) {
        return res(ctx.status(401), ctx.json(SignUpResponse.Message(401)));
    }

    // 유저 회원가입 완료
    return res(ctx.status(200), ctx.json(SignUpResponse.Message(200)));
});

export const loginApi = rest.post(`${login_path}`, (req, res, ctx) => {
    const { email, password, media } = req.body;

    const user = UserMockData.find(
        (user) => user.email === email && user.password === password
    );

    // 유저 로그인 완료
    if (user)
        return res(
            ctx.status(200),
            ctx.json(LoginResponse.Data(200, email, media))
        );

    // 정보 일부 불일치
    if (
        UserMockData.some(
            (user) => user.email === email || user.password === password
        )
    )
        return res(ctx.status(401), ctx.json(LoginResponse.Data(401)));

    // 정보 완전 불일치
    return res(ctx.status(403), ctx.json(LoginResponse.Data(403)));
});
