import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FlexAlignCenter, FlexJustifyBetween } from "../../Styles/common";
import theme from "../../Styles/theme";

const sizeCSS = {
    default: css`
        width: 135px;
        height: 40px;
    `,

    large: css`
        width: 100%;
        height: 48px;
    `,
};

const colorCSS = {
    default: css`
        color: ${theme.COLOR.common.black};
        background: ${theme.COLOR.common.white};
    `,

    primary: css`
        color: ${theme.COLOR.primary[100]};
        background: ${theme.COLOR.common.black};
    `,
};

const shapeCSS = {
    default: css`
        box-sizing: border-box;
        border: 1px solid ${theme.COLOR.common.gray[400]};
        border-radius: 8px;
    `,
};

export const SelectContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const SelectTitle = styled.div`
    ${FlexJustifyBetween}
    align-items: center;
    cursor: pointer;
    padding: 0 16px;

    ${({ size = "default" }) => sizeCSS[size]};
    ${({ shape = "default" }) => shapeCSS[shape]}
`;

export const SelectBox = styled.ul`
    margin-top: 8px;
    position: absolute;
    z-index: 9999;
    width: ${({ path }) => (path.includes("signup") ? "100%" : "135px")};
    overflow: hidden;
    background: ${({ theme }) => theme.COLOR.common.white};

    ${({ shape = "default" }) => shapeCSS[shape]}
`;

export const SelectList = styled.li`
    ${FlexAlignCenter}
    box-sizing: border-box;
    padding: 0 16px;
    ${({ size = "default" }) => sizeCSS[size]}
    cursor: pointer;

    &:hover {
        ${({ color = "primary" }) => colorCSS[color]}
    }
`;

export const ErrorBox = styled.p`
    position: absolute;
    z-index: 999;
    top: 100%;
    margin-top: 8px;

    color: ${({ theme }) => theme.COLOR.state.error};
`;
