import styled from "@emotion/styled";
import { GridNumber } from "Styles/common";
import theme from "Styles/theme";

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: grid;
    align-items: ${({ align }) => (!align ? "center" : align)};
    ${GridNumber("column", 11)}
    padding: 8px 8px 8px 16px;
    border-radius: 8px;
    border: 1px solid ${theme.COLOR.common.gray[500]};
    column-gap: 24px;
    & > h6:first-of-type {
        grid-column: 1/3;
    }
`;

export const InputContainer = styled.div`
    grid-column: 3/12;
    display: ${({ display }) => (display ? display : "auto")};
    flex-direction: ${({ direction }) => (direction ? direction : null)};
    gap: ${({ gap }) => (gap ? gap : "8px")};
    align-items: ${({ display }) => (display ? "center" : null)};
    justify-content: ${({ justify }) => (justify ? justify : null)};
`;

export const TypoLength = styled.div`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: ${theme.COLOR.common.gray[500]};
`;
