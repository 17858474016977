import styled from "@emotion/styled";
import {
    FlexAlignStart,
    GridAlignCenter,
    GridNumber,
    MarginAuto,
} from "Styles/common";

export const Wrapper = styled.section`
    ${MarginAuto}

    & > form {
        ${GridAlignCenter}
        ${GridNumber("column", 3)}
        gap:24px 30px;
    }
`;

export const PaidInfoContainer = styled.div`
    ${FlexAlignStart}
    flex-direction: column;
    grid-column: 1 / 3;
    row-gap: 24px;
`;

export const PaidInfoBox = styled.div`
    width: 100%;
    box-sizing: border-box;
    ${FlexAlignStart}
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    border: 1px solid ${({ theme }) => theme.COLOR.common.gray[500]};
    border-radius: 8px;
`;
