import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import Text from "Components/Text/Text";
import { FlexAlignCenter, FlexAlignStart } from "Styles/common";
import { useNavigate } from "react-router-dom";
import Price from "./Components/Price";

export default function Summary(props) {
    const { product } = props;
    const navigate = useNavigate();

    return (
        <S.Wrapper>
            <S.NavigationContainer>
                <Text type="h6" subType="p" onClick={() => navigate("/")}>
                    애드몬스터
                </Text>
                <Text type="h6" subType="p">
                    {product.media}
                </Text>
                <Text type="h6" subType="p">
                    {product.category}
                </Text>
            </S.NavigationContainer>

            <S.TitleContainer>
                <Text type="h3">{product.title}</Text>
                <Text type="h3" subType="p">
                    {product.description}
                </Text>
            </S.TitleContainer>

            <S.PriceContainer>
                <Price price={product.price} />
            </S.PriceContainer>
            <S.Duration>
                <Text type="h6">제작기간</Text>
                <Text type="h6">4~7일</Text>
            </S.Duration>
            <Button
                size="full"
                onClick={() =>
                    navigate(`/payment/${product.idx}`, {
                        state: product,
                    })
                }
            >
                구매하기
            </Button>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;

    & h3 {
        font-weight: 700;
        line-height: 150%;
    }
`;

const NavigationContainer = styled.div`
    display: flex;

    & > h6:first-of-type:hover {
        cursor: pointer;
    }
    & > :where(p:nth-of-type(1), p:nth-of-type(2)) {
        color: ${({ theme }) => theme.COLOR.common.gray[300]};
        ::after {
            margin: 0 5px;
            content: ">";
        }
    }
`;
const TitleContainer = styled.div`
    ${FlexAlignStart}
    flex-direction: column;
    row-gap: 16px;
    margin: 48px 0;
`;

const PriceContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 34px;

    & > div > div {
        ${FlexAlignCenter}

        & > div {
            ${FlexAlignCenter}

            & > h3:first-of-type {
                margin: 0 8px 0 16px;
            }
        }
    }
`;

const Duration = styled.div`
    ${FlexAlignCenter}
    gap: 1rem;
    margin-bottom: 56px;

    & > h6:first-of-type {
        border: 1px solid black;
        border-radius: 16px;
        padding: 7px 24px;
    }
`;

const S = {
    Wrapper,
    NavigationContainer,
    TitleContainer,
    PriceContainer,
    Duration,
};
