import styled from "@emotion/styled";
import { MarginAuto } from "Styles/common";

export const Wrapper = styled.section`
    ${MarginAuto}

    & > h4 {
        text-align: center;
    }
`;

export const SearchResultNum = styled.p`
    margin: 24px 0 16px 0;
`;
