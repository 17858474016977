import Text from "Components/Text/Text";
import { useNavigate } from "react-router-dom";
import * as S from "./CardB.style";

export default function CardB({ data, ...rest }) {
    const navigate = useNavigate();
    return (
        <S.Card
            onClick={() => navigate(`/product/detail/${data.idx}`)}
            {...rest}
        >
            <S.Thumbnail thumbnail={data.thumbnail} {...rest}></S.Thumbnail>
            <S.TextContainer>
                <Text type="h5">{data.title}</Text>
                <Text type="h5" subType="p">
                    {data.description}
                </Text>
                <p>{Number(data.price.result).toLocaleString()}원 ~</p>
            </S.TextContainer>
        </S.Card>
    );
}
