import { css } from "@emotion/react";
import styled from "@emotion/styled";

const marginCSS = {
    search: css`
        margin: 32px 0 24px 0;
    `,

    detail: css`
        margin: 24px 0 80px 0;
    `,

    payment: css`
        margin: 80px 0 48px 0;
    `,
};

export const Hr = styled.hr`
    ${({ margin }) => marginCSS[margin]}

    width:100%;
    height: 1px;
    background: ${({ theme }) => theme.COLOR.common.gray[400]};
    border: none;
`;
