import styled from "@emotion/styled";
import { GridNumber } from "Styles/common";
import ProductDetail from "./Components/Summary";
import ProductOthers from "./Components/Thumbnail";

export default function ProductTitle(props) {
    const { product } = props;

    return (
        <S.Wrapper>
            <ProductDetail product={product} />
            <ProductOthers url={product.thumbnail} store={product.store} />
        </S.Wrapper>
    );
}

const Wrapper = styled.section`
    display: grid;
    ${GridNumber("column", 2)}
    grid-gap: 96px;
`;

const S = {
    Wrapper,
};
