import Hr from "Components/Hr/Hr";
import Text from "Components/Text/Text";
import { useGetCompletedPayment } from "Hooks/Queries/payment/getCompletedPayment";
import { useNavigate, useParams } from "react-router-dom";
import PaidProduct from "../Components/PaidProduct";
import * as S from "./CompletedPayment.style";

export default function CompletedPayment() {
    const navigate = useNavigate();

    // mocking data 응답값 중 idx가 random값이여서 params X
    const params = useParams();

    const {
        data: product,
        isLoading,
        error,
    } = useGetCompletedPayment({
        productIdx: "352669",
    });

    console.log(product);

    return (
        product && (
            <S.Wrapper>
                <Text type="h1">결제가 완료되었습니다.</Text>
                <Hr margin="payment" />
                <S.CompleteMsgBox>
                    <S.TextContainer>
                        <S.Title>
                            <Text type="h4">구매 내역</Text>
                            <Text type="h4" subType="p">
                                결제일시 : {"날짜(변수)"}
                            </Text>
                        </S.Title>
                        <S.Contents>
                            <Text type="h4" subType="p">
                                {"상호(변수)"}님, 감사합니다. '
                                <b>{product.title}</b>' 결제가 완료되었습니다.
                            </Text>
                            <Text type="h4" subType="p">
                                말씀해주신 요구사항 참고하여 광고 진행하도록
                                하겠습니다.
                            </Text>
                        </S.Contents>
                    </S.TextContainer>
                    <S.StyledButton
                        styles="outline"
                        size="large"
                        onClick={() => navigate("/")}
                    >
                        <Text type="h4"> 홈으로 가기</Text>
                    </S.StyledButton>
                </S.CompleteMsgBox>
                <S.PaidInfoBox>
                    <Text type="h4">결제완료</Text>
                    <PaidProduct product={product} />
                </S.PaidInfoBox>
            </S.Wrapper>
        )
    );
}
