import styled from "@emotion/styled";
import RadioBox from "Components/RadioBox/RadioBox";
import { FlexBetween } from "Styles/common";
import { payment } from "Utils/paymentList";

export default function PaidCredit({ control }) {
    return (
        <S.Wrapper>
            <RadioBox
                control={control}
                name="pay_paidMethod"
                options={payment}
            />
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    ${FlexBetween}
    gap: 150px;
`;

const S = {
    Wrapper,
};
