import styled from "@emotion/styled";
import useCountState from "Hooks/useCountState";
import { FlexCenter } from "Styles/common";

export default function Quantity({ children, ...rest }) {
    const { count, setCount, onIncrease, onDecrease } = useCountState();

    return (
        <S.Wrapper>
            <S.UpDown type="button" onClick={onIncrease}>
                +
            </S.UpDown>
            <S.Num>{count}</S.Num>
            <S.UpDown
                type="button"
                onClick={onDecrease}
                disabled={count <= 1 ? true : false}
            >
                -
            </S.UpDown>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    overflow: hidden;
    height: 32px;
    border: 1px solid ${({ theme }) => theme.COLOR.common.gray[300]};
    border-radius: 4px;
`;
const UpDown = styled.button`
    width: 32px;
    padding: 3px;
    border: none;
    background-color: ${({ theme }) => theme.COLOR.common.white};
    color: ${({ theme }) => theme.COLOR.common.gray[300]};
    cursor: pointer;
`;
const Num = styled.div`
    ${FlexCenter}
    width: 48px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 150%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.COLOR.common.white};
    border-right: 1px solid ${({ theme }) => theme.COLOR.common.gray[300]};
    border-left: 1px solid ${({ theme }) => theme.COLOR.common.gray[300]};
`;
const S = {
    Wrapper,
    UpDown,
    Num,
};
