import { useEffect, useRef, useState } from "react";
import Card from "../Card/Card";
import * as S from "./CardSlider.style";

export default function CardSlider({ data, type }) {
    const [slideIndex, setSlideIndex] = useState(0);
    const slideRef = useRef(null);
    let startX = 0;
    let endX = 0;

    const controlMoved = () => {
        const isMoved = endX - startX;

        if (isMoved > 100) {
            onPrev();
        }

        if (isMoved < -100) {
            onNext();
        }
    };

    const onTouchStart = (e) => {
        startX = e.changedTouches[0].clientX;
    };

    const onTouchMove = (e) => {
        endX = e.changedTouches[0].clientX;
    };

    const onMouseDown = (e) => {
        startX = e.clientX;
    };

    const onMouseMove = (e) => {
        endX = e.clientX;
    };

    const onTouchEnd = () => {
        controlMoved();
    };

    const onNext = () => {
        if (slideIndex < data.slice(0, 12).slice(0, 4).length - 1) {
            setSlideIndex(slideIndex + 1);
        }
    };

    const onPrev = () => {
        if (slideIndex > 0) {
            setSlideIndex(slideIndex - 1);
        }
    };

    useEffect(() => {
        const slide = () => {
            if (slideRef.current) {
                slideRef.current.style.transform = `translateX(
                        -${(slideRef.current.offsetWidth + 30) * slideIndex}px
                        )`;
            }
        };

        slide();
    }, [slideIndex]);

    return (
        <S.SlideContainer>
            <Card
                type={type}
                data={data}
                display="flex"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onTouchEnd}
                ref={slideRef}
            />
            <S.StyledButton onClick={onPrev} type={type}>
                &lt;
            </S.StyledButton>
            <S.StyledButton onClick={onNext} type={type}>
                &gt;
            </S.StyledButton>
        </S.SlideContainer>
    );
}
