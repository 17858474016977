export const SignUpResponse = {
    Message(status) {
        if (status === 200) {
            return { message: "success" };
        } else {
            return { message: "failure" };
        }
    },
};

export const LoginResponse = {
    Data(status, email, media) {
        if (status === 200) {
            return {
                // buyer account
                email: `${email}`,
                interestMedia: `${media}`,
                profileUrl: "",
                store: {
                    idx: "",
                    name: "",
                    address: "",
                    tel: "",
                    profileUrl: "",
                },
                tokenForHeader: "sdfklsdjfljdslkfj342235nkj5kjqbl",
            };
        }

        if (status === 401) {
            return { message: "아이디 혹은 비밀번호가 일치하지 않습니다." };
        }

        if (status === 403) {
            return { message: "가입되지 않은 회원 정보입니다." };
        }
    },
};
