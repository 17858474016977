import { rest } from "msw";
import { productMockData } from "../ProductInfo/ProductMock";

const payment_path = "/api/payment/completed";

export const CompletedPaymentApi = rest.get(
    `${payment_path}`,
    (req, res, ctx) => {
        const searchParams = new URLSearchParams(req.url.searchParams);
        const productIdx = searchParams.get("productIdx");

        if (productIdx) {
            const paymentProduct = productMockData.find(
                (it) => parseInt(it.idx) === parseInt(productIdx)
            );

            return res(ctx.status(200), ctx.json(paymentProduct));
        }

        return res(
            ctx.status(404),
            ctx.json({ message: "잘못된 호출입니다." })
        );
    }
);
