import { forwardRef } from "react";
import IconCollection from "../Icons/IconCollection";
import * as S from "./SelectElement.style";

function SelectElement(
    { children, field, error, onClick, media, ...restProps },
    ref
) {
    return (
        <S.SelectContainer {...restProps}>
            <S.SelectTitle onClick={onClick} {...restProps}>
                <p>
                    {field?.value
                        ? field?.value
                        : media
                        ? media
                        : "선택하세요."}
                </p>
                <IconCollection size="medium" children="keyboard_arrow_down" />
            </S.SelectTitle>
            {children && <S.SelectBox {...restProps}>{children}</S.SelectBox>}
            {error && <S.ErrorBox>{error}</S.ErrorBox>}
        </S.SelectContainer>
    );
}

export default forwardRef(SelectElement);
