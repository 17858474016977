export const soldState = [
    {
        id: 1,
        value: true,
        name: "판매함",
    },
    {
        id: 2,
        value: false,
        name: "판매안함",
    },
];
