import secureLocalStorage from "react-secure-storage";
import { LOCAL_STORAGE_KEY } from "../Consts/Key/storage.key";

export const UserInfoManager = {
    setUser(userInfo) {
        secureLocalStorage.setItem(
            LOCAL_STORAGE_KEY.USER_INFO,
            JSON.stringify(userInfo)
        );
    },

    getUser() {
        return JSON.parse(
            secureLocalStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO)
        );
    },

    removeUser() {
        secureLocalStorage.removeItem(LOCAL_STORAGE_KEY.USER_INFO);
    },
};
