import Hr from "Components/Hr/Hr";
import Text from "Components/Text/Text";
import useCountState from "Hooks/useCountState";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import OtherRequirement from "../Components/OtherRequirement";
import PaidCredit from "../Components/PaidCredit";
import PaidProduct from "../Components/PaidProduct";
import PaidSubmit from "../Components/PaidSubmit";
import * as S from "./Payment.style";

export default function Payment() {
    const { state: product } = useLocation();
    const { count, setCount, onIncrease, onDecrease } = useCountState();
    const { control, setValue, handleSubmit } = useForm({
        defaultValues: {
            pay_paidMethod: "creditcard",
            pay_requirement: "",
            pay_serviceAgree: false,
            pay_overAgeAgree: false,
            pay_privateInfoAgree: false,
            pay_marketingAgree: false,
        },
    });

    const onSubmitHandler = (data) => {
        const { pay_paidMethod, pay_requirement, pay_marketingAgree } = data;
        const newData = { pay_paidMethod, pay_requirement, pay_marketingAgree };

        const formData = {
            ...newData,
            store_idx: product.store.idx,
            product_idx: product.idx,
            product_title: product.title,
            price: product.price.result * count,
            quantity: count,
        };

        try {
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <S.Wrapper>
            <Text type="h1">
                최종 결제액 :{" "}
                {Number(product.price.result * count).toLocaleString()}원
            </Text>
            <Hr margin="payment" />
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <S.PaidInfoContainer>
                    <S.PaidInfoBox>
                        <Text type="h4">결제하기</Text>
                        <PaidProduct product={product} count={count} />
                    </S.PaidInfoBox>
                    <S.PaidInfoBox>
                        <Text type="h4">결제 수단</Text>
                        <PaidCredit control={control} />
                    </S.PaidInfoBox>
                    <S.PaidInfoBox>
                        <Text type="h4">이외 정보</Text>
                        <OtherRequirement
                            control={control}
                            setValue={setValue}
                        />
                    </S.PaidInfoBox>
                </S.PaidInfoContainer>
                <PaidSubmit product={product} count={count} control={control} />
            </form>
        </S.Wrapper>
    );
}
