import styled from "@emotion/styled";
import FormElement from "Components/FormElement/FormElement";
import Text from "Components/Text/Text";
import * as S from "./RegisterOption.style";

export default function RegisterWorkDuration({ control }) {
    return (
        <S.Wrapper>
            <Text type="h6" subType="p">
                제작기간
            </Text>
            <S.InputContainer display="flex">
                <MinBox>
                    <FormElement
                        control={control}
                        name="min"
                        placeholder="최소"
                        size="small"
                        color="none"
                        align="right"
                        p_textAlign="center"
                    />
                    <Days>
                        <Text type="h6" subType="p">
                            일
                        </Text>
                    </Days>
                </MinBox>
                <Text type="h6" subType="p">
                    ~
                </Text>
                <MaxBox>
                    <FormElement
                        control={control}
                        name="max"
                        placeholder="최대"
                        size="small"
                        color="none"
                        align="right"
                        p_textAlign="center"
                    />
                    <Days>
                        <Text type="h6" subType="p">
                            일
                        </Text>
                    </Days>
                </MaxBox>
            </S.InputContainer>
        </S.Wrapper>
    );
}

const MinBox = styled.div`
    position: relative;
`;
const MaxBox = styled.div`
    position: relative;
`;

const Days = styled.div`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
`;
