import { css } from "@emotion/react";
import styled from "@emotion/styled";

const defaultCSS = css`
    line-height: 100%;
`;

const styleCSS = {
    h1: css`
        font-size: 4.8rem;
        text-align: center;
    `,

    h2: css`
        font-size: 4.8rem;
    `,

    h3: css`
        font-size: 2.4rem;
    `,

    h3p: css`
        font-size: 1.8rem;
        font-weight: 400;
    `,

    h4: css`
        font-size: 2rem;
    `,

    h4p: css`
        font-size: 1.4rem;
        font-weight: 400;
    `,

    h5: css`
        font-size: 1.8rem;
    `,

    h5p: css`
        font-size: 1.6rem;
        font-weight: 400;
    `,

    h6: css`
        font-size: 1.6rem;
    `,

    h6p: css`
        font-size: 1.6rem;
        font-weight: 400;
    `,
};

export const Text = ({ type, subType, children }) => {
    let styleProperty = subType ? type + subType : type;

    const TextComponent = styled(subType ? subType : type)`
        ${defaultCSS};
        ${styleCSS[styleProperty]};
    `;

    return <TextComponent>{children}</TextComponent>;
};
