import * as PaymentApi from "./mockApis/PaymentInfo/PaymentApi";
import * as ProductApi from "./mockApis/ProductInfo/ProductApi";
import * as SearchApi from "./mockApis/SearchInfo/SearchApi";
import * as UserApi from "./mockApis/UserInfo/UserApi";

export const handler = [
    ...Object.values(UserApi),
    ...Object.values(ProductApi),
    ...Object.values(SearchApi),
    ...Object.values(PaymentApi),
];
