import styled from "@emotion/styled";
import { searchBarStateAtom } from "Atom/searchBarState.atom";
import Button from "Components/Button/Button";
import IconCollection from "Components/Icons/IconCollection";
import SearchBar from "Components/SearchBar/SearchBar";
import { FlexAlignCenter, FlexBetween, MarginAuto } from "Styles/common";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import Logo from "../Components/Logo";

export default function Header() {
    const navigate = useNavigate();
    const [barState, setBarState] = useRecoilState(searchBarStateAtom);

    return (
        <S.Wrapper>
            <SearchBar barState={barState} setBarState={setBarState} />
            <S.Container>
                <Logo />
                <S.ActionContainer>
                    <IconCollection
                        size="medium"
                        children={"search"}
                        onClick={() => setBarState((prev) => !prev)}
                    />
                    <S.SignContainer>
                        <StyledButton
                            styles="outline"
                            login={true}
                            onClick={() => navigate("/login")}
                        >
                            로그인
                        </StyledButton>
                        <StyledButton onClick={() => navigate("/signup")}>
                            회원가입
                        </StyledButton>
                    </S.SignContainer>
                </S.ActionContainer>
            </S.Container>
        </S.Wrapper>
    );
}

const Wrapper = styled.header`
    position: absolute;
    width: 100%;
    z-index: 9999;
    border-bottom: 0.1rem solid ${({ theme }) => theme.COLOR.common.gray[400]};
`;

const Container = styled.div`
    ${MarginAuto}
    ${FlexBetween}
    height: 80px;
`;

const ActionContainer = styled.div`
    ${FlexAlignCenter}
`;

const SignContainer = styled.div`
    margin-left: 1.6rem;
`;

const StyledButton = styled(Button)`
    font-weight: 300;

    &:first-of-type {
        margin-right: 1.6rem;
    }
`;

const S = {
    Wrapper,
    Container,
    ActionContainer,
    SignContainer,
    StyledButton,
};
