import { Global, css } from "@emotion/react";

const global = css`
    * {
        margin: 0;
        padding: 0;
        line-height: 100%;
    }

    html {
        font-size: 62.5%;
        font-family: "Spoqa Han Sans Neo", "sans-serif";
    }

    section {
        padding: 160px 0 0 0;
    }

    ul,
    li {
        list-style: none;
        font-size: 1.4rem;
    }

    a {
        font-family: "Spoqa Han Sans Neo", "sans-serif";
        text-decoration: none;
        color: black;
    }

    button {
        font-family: "Spoqa Han Sans Neo", "sans-serif";
        font-weight: 700;
    }

    input {
        font-family: "Spoqa Han Sans Neo", "sans-serif";
        padding: 0 16px;
    }

    select {
        font-family: "Spoqa Han Sans Neo", "sans-serif";
        padding: 0 16px;
    }

    p {
        font-size: 1.4rem;
    }

    span {
        font-size: 1.2rem;
    }
`;

export default function GlobalStyles() {
    return <Global styles={global} />;
}
