import { css } from "@emotion/react";
import styled from "@emotion/styled";
import theme from "Styles/theme";

const defaultStyle = css`
    box-sizing: border-box;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    font-size: 1.8rem;
    font-weight: 400;
    color: ${theme.COLOR.common.white};
    cursor: pointer;
`;

const Size = (size) => {
    if (size === "small") {
        return css`
            width: 72px;
            font-size: 1.4rem;
            padding: 10px;
        `;
    }

    if (size === "medium")
        return css`
            width: 98px;
            font-size: 2rem;
            padding: 9px 12px;
        `;

    if (size === "large")
        return css`
            width: 117px;
            font-size: 2.4rem;
            padding: 10px 14px;
            font-weight: 700;
        `;

    if (size === "full")
        return css`
            font-size: 1.8rem;
            width: 100%;
            height: 48px;
        `;
};

const CSS = {
    primary: (button) => {
        return css`
            background: ${theme.COLOR.primary[100]};
            :hover {
                background: ${theme.COLOR.primary[200]};
            }
            :active {
                background: ${theme.COLOR.primary[300]};
            }
            :disabled {
                background: ${theme.COLOR.primary[500]};
            }
        `;
    },

    secondary: (button) => {
        return css`
            background: ${theme.COLOR.common.black[100]};
            :hover {
                background: ${theme.COLOR.common.black[200]};
            }
            :active {
                background: ${theme.COLOR.common.gray[100]};
            }

            :disabled {
                background: ${theme.COLOR.common.gray[600]};
                color: ${theme.COLOR.common.gray[400]};
            }
        `;
    },

    tertiary: (button) => {
        return css`
            color: ${theme.COLOR.common.gray[100]};
            background: ${theme.COLOR.common.gray[600]};
            :hover {
                background: ${theme.COLOR.common.gray[500]};
            }
            :active {
                background: ${theme.COLOR.common.gray[400]};
            }
            :disabled {
                color: ${theme.COLOR.common.gray[400]};
                background: ${theme.COLOR.common.gray[600]};
            }
        `;
    },
    outline: (button) => {
        return css`
            color: ${theme.COLOR.common.gray[100]};
            background: ${theme.COLOR.common.white};
            border: ${button.login
                ? "none"
                : `1px solid ${theme.COLOR.common.gray[500]}`};
        `;
    },
};

export const Button = styled.button`
    ${defaultStyle}
    ${({ styles = "primary" }) => CSS[styles]}
    ${({ size }) => Size(size)}
`;
