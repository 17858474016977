import styled from "@emotion/styled";
import Hr from "Components/Hr/Hr";
import { useGetDetailProduct } from "Hooks/Queries/detail/getDetailProduct";
import { MarginAuto } from "Styles/common";
import { useParams } from "react-router-dom";
import AgencyProduct from "./Components/AgencyProduct";
import ProductInfo from "./Components/ProductInfo";
import ProductTitle from "./Components/ProductTitle/ProductTitle";
import RelatedProduct from "./Components/RelatedProduct";

export default function Product() {
    const params = useParams();
    const { idx } = params;
    const { data, isLoading, error } = useGetDetailProduct(idx);

    if (isLoading) return;
    if (error) return;

    return (
        data && (
            <S.Wrapper>
                <ProductTitle product={data} />
                <Hr margin="detail" />
                <AgencyProduct agency={data.store} />
                <ProductInfo product={data} />
                <RelatedProduct category={data.category} />
            </S.Wrapper>
        )
    );
}

const Wrapper = styled.div`
    ${MarginAuto}
`;

const S = {
    Wrapper,
};
