import styled from "@emotion/styled";
import Text from "Components/Text/Text";
import { useEffect, useRef, useState } from "react";

export default function Price(props) {
    const { price } = props;

    const widthRef = useRef(null);
    const [isWidth, setIsWidth] = useState();

    useEffect(() => {
        if (widthRef.current) {
            setIsWidth(widthRef.current?.offsetWidth);
        }
    }, []);

    const coloredChange = price?.normal;

    const discountRate =
        ((price.normal - price.result) / price.normal) * 100 + "%";
    return (
        <S.Wrapper>
            <PriceColored colored={coloredChange}>
                <Text type="h3">정상가</Text>
                <div>
                    <Text type="h3">￦</Text>
                    <PriceHeading ref={widthRef}>
                        {Number(price.normal).toLocaleString()}
                    </PriceHeading>
                </div>
            </PriceColored>

            <PriceColored>
                <Text type="h3">할인가</Text>
                <div>
                    <Text type="h3">￦</Text>
                    <PriceHeading isWidth={isWidth}>
                        {Number(price.result).toLocaleString()}
                    </PriceHeading>
                </div>
                <h5>{discountRate}</h5>
            </PriceColored>
        </S.Wrapper>
    );
}

const Wrapper = styled.div``;
const PriceColored = styled.div`
    color: ${({ colored }) => (colored ? "gray" : "red")};

    & > h5 {
        margin-left: 16px;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 150%;
        border-radius: 8px;
        padding: 0 8px;
        background-color: red;
        color: ${({ theme }) => theme.COLOR.common.white};
    }
    &:last-of-type > h3:first-of-type {
        color: black;
    }
`;
const PriceHeading = styled.h3`
    width: ${({ isWidth }) => `${isWidth}px`};
    font-size: 2.4rem;
    text-align: right;
`;

const S = {
    Wrapper,
    PriceHeading,
};
