import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY } from "../../../Consts/Key/query.key";

export const useGetDetailProduct = (idx) => {
    const { data, isLoading, error } = useQuery(
        [QUERY_KEY.GET_DETAIL_PRODUCT],
        async () => {
            try {
                const res = await axios.get("/api/product", {
                    params: {
                        productIdx: idx,
                    },
                });
                return res.data;
            } catch (error) {
                console.log(error);
            }
        },
        {
            staleTime: "",
            cacheTime: "",
        }
    );

    return { data, isLoading, error };
};
