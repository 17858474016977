import styled from "@emotion/styled";
import { FlexAlignCenter, FlexAlignStart, MarginAuto } from "Styles/common";

export const Wrapper = styled.div`
    ${MarginAuto}
    ${FlexAlignStart}
    flex-direction: column;
    padding-top: 160px;
    & > form:first-of-type {
        width: 100%;
        margin-top: 32px;
    }
`;

export const ProductRegisterContainer = styled.div`
    ${FlexAlignCenter}
    flex-direction: column;
    gap: 16px;

    & > :where(div:nth-last-of-type(3)) {
        & > p {
            padding-top: 16px;
        }
    }
    & > :where(div:nth-of-type(7)) {
        & > p {
            padding-top: 16px;
        }
    }
    & > :where(div:nth-of-type(8)) {
        & > p {
            padding-top: 16px;
        }
    }
    & > :where(div:last-of-type) {
        padding: 20px 8px 20px 16px !important;
        & > div {
            margin-left: 16px;
        }
    }
`;
