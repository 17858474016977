import styled from "@emotion/styled";
import { FlexCenter } from "../../Styles/common";

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    ${FlexCenter}
    background: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
    width: 37.6rem;
    height: 37.6rem;
    ${FlexCenter}
    flex-direction: column;
    border-radius: 16px;
    background: ${({ theme }) => theme.COLOR.common.white};
    z-index: 9999;
`;

export const Message = styled.p`
    font-size: 2rem;
    margin: 3.2rem 0 6.4rem 0;
`;
