import CompletedPayment from "Pages/Payment/CompletedPage/CompletedPayment";
import Payment from "Pages/Payment/PaymentPage/Payment";
import { createBrowserRouter } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import SignIn from "../Pages/Form/SignIn/SignIn";
import SignUpStepFirst from "../Pages/Form/SignUp/Pages/Step_1";
import SignUpStepSecond from "../Pages/Form/SignUp/Pages/Step_2";
import SignUp from "../Pages/Form/SignUp/index";
import Main from "../Pages/Main/Main";
import UserType from "../Pages/MyApp/Common/Components/UserType";
import CommonAsk from "../Pages/MyApp/Common/Pages/Ask/CommonAsk";
import CommonProfile from "../Pages/MyApp/Common/Pages/Profile/CommonProfile";
import AskManager from "../Pages/MyApp/Pages/Admin/Pages/AskManager/AskManager";
import CompletedAll from "../Pages/MyApp/Pages/Admin/Pages/CalculateAll/Completed/CompletedAll";
import MemberManager from "../Pages/MyApp/Pages/Admin/Pages/MemberManager/MemberManager";
import SalesAllHistory from "../Pages/MyApp/Pages/Admin/Pages/SalesAllHistory/SalesAllHistory";
import ProductListAll from "../Pages/MyApp/Pages/Admin/Pages/StoreManager/ProductList/ProductListAll";
import StoreList from "../Pages/MyApp/Pages/Admin/Pages/StoreManager/StoreList/StoreList";
import OrderHistory from "../Pages/MyApp/Pages/Buyer/Pages/OrderHistory/OrderHistory";
import Completed from "../Pages/MyApp/Pages/Seller/Pages/Calculate/Completed/Completed";
import Intended from "../Pages/MyApp/Pages/Seller/Pages/Calculate/Intended/Intended";
import TaxDocu from "../Pages/MyApp/Pages/Seller/Pages/Calculate/TaxDocu/TaxDocu";
import ProductList from "../Pages/MyApp/Pages/Seller/Pages/Product/List/ProductList";
import ProductRegister from "../Pages/MyApp/Pages/Seller/Pages/Product/Register/ProductRegister";
import SalesHistory from "../Pages/MyApp/Pages/Seller/Pages/SalesHistory/SalesHistory";
import PartnerAsk from "../Pages/PartnerAsk/PartnerAsk";
import Policy from "../Pages/Policy/Policy";
import Product from "../Pages/Product/Product";
import SearchResult from "../Pages/SearchResult/SearchResult";
import PrivateRoute from "./PrivateRoute";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <Main />,
            },
            {
                path: "/login",
                element: <SignIn />,
            },
            {
                path: "/signup",
                element: <SignUp />,
            },
            {
                path: "/signup/step1",
                element: <SignUpStepFirst />,
            },
            {
                path: "/signup/step2",
                element: <SignUpStepSecond />,
            },

            {
                element: <PrivateRoute />,
                children: [
                    {
                        element: <UserType />,
                        children: [
                            {
                                path: "/admin/ask-manager",
                                element: <AskManager />,
                            },
                            {
                                path: "/admin/calculate/completed",
                                element: <CompletedAll />,
                            },
                            {
                                path: "/admin/calculate/intended",
                                element: <CompletedAll />,
                            },
                            {
                                path: "/admin/calculate/tax",
                                element: <CompletedAll />,
                            },
                            {
                                path: "/admin/member-manager",
                                element: <MemberManager />,
                            },
                            {
                                path: "/admin/sales-history",
                                element: <SalesAllHistory />,
                            },
                            {
                                path: "/admin/store/product-all",
                                element: <ProductListAll />,
                            },
                            {
                                path: "/admin/store/list",
                                element: <StoreList />,
                            },
                        ],
                    },

                    {
                        element: <UserType />,
                        children: [
                            {
                                path: "/buyer/profile",
                                element: <CommonProfile />,
                            },
                            {
                                path: "/buyer/ask",
                                element: <CommonAsk />,
                            },
                            {
                                path: "/buyer/order-list",
                                element: <OrderHistory />,
                            },
                        ],
                    },
                    {
                        element: <UserType />,
                        children: [
                            {
                                path: "/seller/profile",
                                element: <CommonProfile />,
                            },
                            {
                                path: "/seller/ask",
                                element: <CommonAsk />,
                            },
                            {
                                path: "/seller/calculate/completed",
                                element: <Completed />,
                            },
                            {
                                path: "/seller/calculate/intended",
                                element: <Intended />,
                            },
                            {
                                path: "/seller/calculate/tax",
                                element: <TaxDocu />,
                            },
                            {
                                path: "/seller/product/list",
                                element: <ProductList />,
                            },
                            {
                                path: "/seller/product/register",
                                element: <ProductRegister />,
                            },
                            {
                                path: "/seller/sales-history",
                                element: <SalesHistory />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "/product/detail/:idx",
                element: <Product />,
            },
            {
                path: "/search/:keyword",
                element: <SearchResult />,
            },
            {
                path: "/policy",
                element: <Policy />,
            },
            {
                path: "/payment/:idx",
                element: <Payment />,
            },
            {
                path: "/payment/:idx/completed",
                element: <CompletedPayment />,
            },
            {
                path: "/partner-ask",
                element: <PartnerAsk />,
            },
        ],
    },
]);

export default router;
