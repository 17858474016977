import { css } from "@emotion/react";
import styled from "@emotion/styled";
import theme from "../../../Styles/theme";

const sizeCSS = {
    default: css`
        font-size: 1.6rem;
    `,
};

const shapeCSS = {
    default: css`
        border-radius: 1rem;
    `,
    soft: css`
        border-radius: 0.8rem;
    `,
};

const colorCSS = {
    default: css`
        background: ${theme.COLOR.common.white};
    `,
};

export const Card = styled.div`
    ${({ size = "default" }) => sizeCSS[size]}
    ${({ shape = "default" }) => shapeCSS[shape]}
    ${({ color = "default" }) => colorCSS[color]}
    margin-bottom: ${({ display }) => (display === "grid" ? "72px" : "0")};

    &:hover {
        cursor: pointer;
    }
`;

export const Thumbnail = styled.div`
    width: 27.6rem;
    height: 17.6rem;
    background: ${({ thumbnail }) => `url(${thumbnail})`}no-repeat center center;
    background-size: cover;
    ${({ shape = "default" }) => shapeCSS[shape]}
`;

export const TextContainer = styled.div`
    width: 100%;
    padding-top: 24px;

    & > p:first-of-type {
        margin: 8px 0 32px 0;

        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    & > p:last-of-type {
        font-size: 1.6rem;
        font-weight: 700;
    }
`;
