import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import { FlexCenter } from "Styles/common";

export const Wrapper = styled.section`
    width: 378px;
    margin: 0 auto;
`;

export const SignInMediaContainer = styled.div`
    ${FlexCenter}
    flex-direction:column;
    margin: 80px 0 0 0;

    & > a {
        display: block;
        width: 100%;
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
`;

export const SignUpNavContainer = styled.div`
    margin-top: 48px;
    text-align: center;

    & > p {
        margin-bottom: 8px;
    }
`;

export const StyledButton = styled(Button)`
    ${FlexCenter}
    background-color: ${({ media }) => media === "구글" && "white"};

    & > h6 {
        color: ${({ media }) => (media === "네이버" ? "white" : "black")};
    }
`;

export const MediaSymbol = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background: ${({ backDrop_img }) => `url(${backDrop_img})`} no-repeat center
        center;
    background-size: contain;
`;
