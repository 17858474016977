import { css } from "@emotion/react";
import styled from "@emotion/styled";

import theme from "../../../Styles/theme";
const sizeCSS = {
    default: css`
        padding: 48px 40px;
        font-size: 1.6rem;
    `,
};

const shapeCSS = {
    default: css`
        border-radius: 1rem;
    `,
    soft: css`
        border-radius: 0.8rem;
    `,
};

const colorCSS = {
    default: css`
        background: ${theme.COLOR.common.gray[600]};
    `,
};

export const Card = styled.div`
    ${({ size = "default" }) => sizeCSS[size]}
    ${({ shape = "default" }) => shapeCSS[shape]}
    ${({ color = "default" }) => colorCSS[color]}

    text-align: center;
    flex-shrink: 0;

    & :hover {
        cursor: pointer;
    }
`;

export const Thumbnail = styled.div`
    width: 29.8rem;
    height: 33.8rem;
    background: ${({ thumbnail }) => `url(${thumbnail})`} no-repeat center
        center;
    background-size: cover;
    ${({ shape = "default" }) => shapeCSS[shape]}
`;

export const TextContainer = styled.div`
    padding-top: 24px;

    & > p:first-of-type {
        margin: 16px 0 48px 0;
    }

    & > p:last-of-type {
        font-size: 1.6rem;
        font-weight: 700;
    }
`;
