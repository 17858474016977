import styled from "@emotion/styled";
import { FlexJustifyLeft, GridCenter, GridNumber } from "../../Styles/common";

export const Wrapper = styled.div`
    ${({ display }) => display === "flex" && FlexJustifyLeft}
    ${({ display }) => display === "grid" && GridCenter}

  ${GridNumber("column", 4)}
    width: 100%;
    gap: 30px;
    transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover {
        cursor: pointer;
    }
`;
