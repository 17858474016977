import styled from "@emotion/styled";
import Button from "Components/Button/Button";
import IconCollection from "Components/Icons/IconCollection";
import Text from "Components/Text/Text";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlexAlignCenter, FlexCenter } from "Styles/common";
import theme from "Styles/theme";

export default function SignUpStepFirst() {
    const [choiceUser, setChoiceUser] = useState();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(window.location.search);
    const snsType = searchParams.get("sns");

    const onClickUserType = (type) => {
        setChoiceUser(type);
    };

    const onSecondStep = () => {
        if (choiceUser) {
            navigate(`/signup/step2?userType=${choiceUser}&snsType=${snsType}`);
        }
    };

    return (
        <S.Wrapper>
            <Text type="h1">
                당신은
                <br />
                누구신가요?
            </Text>
            <S.UserTypeContainer>
                <S.StyledButton
                    styles="tertiary"
                    style={{
                        backgroundColor:
                            choiceUser === "1"
                                ? `${theme.COLOR.common.gray[600]}`
                                : `${theme.COLOR.common.white}`,
                    }}
                    onClick={() => onClickUserType("1")}
                >
                    <Text type="h5">클라이언트</Text>
                    <div>
                        <IconCollection children={"home"} />
                        <p>숙박시설 대표님</p>
                    </div>
                </S.StyledButton>
                <S.StyledButton
                    styles="tertiary"
                    style={{
                        backgroundColor:
                            choiceUser === "2"
                                ? `${theme.COLOR.common.gray[600]}`
                                : `${theme.COLOR.common.white}`,
                    }}
                    onClick={() => onClickUserType("2")}
                >
                    <Text type="h5">대행사</Text>
                    <div>
                        <IconCollection children={"ads_click"} />
                        <p>대행사 담당자님</p>
                    </div>
                </S.StyledButton>
            </S.UserTypeContainer>
            <Button size="full" styles="tertiary" onClick={onSecondStep}>
                다음으로 가기
            </Button>
        </S.Wrapper>
    );
}

const Wrapper = styled.section`
    width: 378px;
    margin: 0 auto;

    ${FlexCenter}
    flex-direction: column;
`;

const UserTypeContainer = styled.div`
    ${FlexAlignCenter}
    margin:80px 0 48px;
    height: 189px;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
    width: 189px;
    height: 100%;
    color: ${({ theme }) => theme.COLOR.common.black[100]};
    background: ${({ theme }) => theme.COLOR.common.white};

    & > div {
        font-weight: 400;
        margin-top: 8px;
        ${FlexCenter}

        & > p {
            margin-left: 4px;
        }
    }
`;

const S = {
    Wrapper,
    UserTypeContainer,
    StyledButton,
};
