import styled from "@emotion/styled";
import FormElement from "Components/FormElement/FormElement";
import Text from "Components/Text/Text";
import { FlexAlignCenter } from "Styles/common";
import * as S from "./RegisterOption.style";

export default function RegisterPrice({ control }) {
    return (
        <S.Wrapper>
            <Text type="h6" subType="p">
                판매가
            </Text>
            <S.InputContainer display="flex" gap="80px">
                <PriceBox>
                    <FormElement
                        control={control}
                        name="normal"
                        color="none"
                        align="right"
                        placeholder="정상가 가능"
                    />
                    <KrWon>
                        <Text type="h6" subType="p">
                            원
                        </Text>
                    </KrWon>
                </PriceBox>
                <PriceBox>
                    <Text type="h6" subType="p">
                        할인가
                    </Text>
                    <FormElement
                        control={control}
                        placeholder="공백 가능"
                        name="discount"
                        size="large"
                        align="right"
                    />
                    <KrWon>
                        <Text type="h6" subType="p">
                            원
                        </Text>
                    </KrWon>
                </PriceBox>
            </S.InputContainer>
        </S.Wrapper>
    );
}

const PriceBox = styled.div`
    position: relative;
    ${FlexAlignCenter}
    width: 100%;
    gap: 112px;
    & > p {
        flex-shrink: 0 !important;
    }
`;

const KrWon = styled.div`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
`;
