import React, { forwardRef } from "react";
import CardA from "./A/CardA";
import CardB from "./B/CardB";
import * as S from "./Card.style";

export default forwardRef(function Card(
    { type, setSlide, data, ...rest },
    ref
) {
    return (
        <S.Wrapper type={type} ref={ref} {...rest}>
            {type === "A" &&
                data?.map((item, idx) => {
                    return <CardA key={idx} data={item} {...rest} />;
                })}
            {type === "B" &&
                data?.map((item, idx) => {
                    return <CardB key={idx} data={item} {...rest} />;
                })}
        </S.Wrapper>
    );
});
