import { productMockData } from "./ProductMock";

export const ProductResponse = {
    create(status) {
        if (status === 200) {
            return { product_idx: "999" };
        } else {
            return { message: "failure" };
        }
    },

    read(status, interestMedia) {
        if (status !== 200) return;

        if (interestMedia) {
            return interestMedia;
        }

        return productMockData;
    },

    update(status) {
        if (status === 200) {
            return { message: "success" };
        } else {
            return { message: "failure" };
        }
    },

    delete(status, idx) {
        if (status === 200) {
            return { product: idx };
        } else {
            return { message: "failure" };
        }
    },
};
