import styled from "@emotion/styled";
import CardSlider from "Components/Slider/CardSlider";
import Text from "Components/Text/Text";

export default function AgencyProduct(props) {
    const { agency } = props;

    return (
        <S.Wrapper>
            <Text type="h3">{agency.name}</Text>
            <CardSlider data={agency.products} type="B" />
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    overflow: hidden;
    & > h3 {
        margin-bottom: 24px;
    }
`;

const S = {
    Wrapper,
};
