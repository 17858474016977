import styled from "@emotion/styled";
import { selectedStateAtom } from "Atom/selectedState.atom";
import FormElement from "Components/FormElement/FormElement";
import IconCollection from "Components/Icons/IconCollection";
import SelectList from "Components/SelectElement/SelectList";
import Text from "Components/Text/Text";
import { selectMedia } from "Utils/selectList";
import { useRecoilState } from "recoil";
import * as S from "./RegisterOption.style";

export default function RegisterCategory({ control, setValue }) {
    const [selectedMedia, setSelectedMedia] = useRecoilState(selectedStateAtom);

    const onClickFirstCategory = (e) => {
        e.preventDefault();
        const { innerText } = e.target;
        setValue("category", innerText);
    };

    return (
        <S.Wrapper align="flex-start">
            <Text type="h6" subType="p">
                카테고리
            </Text>
            <S.InputContainer display="flex" direction="column">
                <CategoryBox>
                    <FormElement
                        control={control}
                        name="category"
                        size="large"
                        color="none"
                        placeholder="상위 카테고리"
                        disabled={true}
                        innerText={selectedMedia}
                    />
                    <IconCollection
                        size="medium"
                        children="keyboard_arrow_down"
                        onClick={() => setSelectedMedia((prev) => !prev)}
                    />
                    {selectedMedia && (
                        <SelectList
                            list={selectMedia}
                            setState={setSelectedMedia}
                            onClick={onClickFirstCategory}
                        />
                    )}
                </CategoryBox>
                <CategoryBox>
                    <FormElement
                        control={control}
                        name="scategory"
                        color="none"
                        placeholder="하위 카테고리"
                        disabled={true}
                    />
                    <IconCollection
                        size="medium"
                        children="keyboard_arrow_down"
                    />
                </CategoryBox>
            </S.InputContainer>
        </S.Wrapper>
    );
}

const CategoryBox = styled.div`
    position: relative;
    width: 100%;
    & > span {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
    }
`;
