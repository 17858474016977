import styled from "@emotion/styled";
import IconCollection from "Components/Icons/IconCollection";
import Input from "Components/Input/Input";
import { FlexAlignCenter, MarginAuto } from "Styles/common";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function SearchAction({ barState }) {
    const { control, handleSubmit } = useForm({
        defaultValues: { keyword: "" },
    });
    const navigate = useNavigate();
    const searchActionRef = useRef(null);

    const onSubmitHandler = async (data) => {
        const { keyword } = data;
        navigate(`search/${keyword}`);
    };

    useEffect(() => {
        if (barState && searchActionRef) {
            searchActionRef.current.style.opacity = "1";
            searchActionRef.current.style.display = "flex";
        } else {
            searchActionRef.current.style.opacity = "0";
            searchActionRef.current.style.display = "none";
        }
    }, [barState]);

    return (
        <S.Wrapper ref={searchActionRef}>
            <IconCollection size="large" color="common" children="search" />
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Controller
                    name="keyword"
                    control={control}
                    rules={{ required: "필수값" }}
                    render={({ field }) => {
                        return (
                            <S.StyledInput
                                size="large"
                                color="none"
                                placeholder="검색어를 입력해주세요."
                                {...field}
                            />
                        );
                    }}
                />
            </form>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    ${MarginAuto}
    ${FlexAlignCenter}
    opacity: 0;
    padding-top: 40px;
    transition: 1s cubic-bezier(0.165, 0.84, 0.44, 1);

    & > form {
        width: 100%;
        margin-left: 8px;
    }
`;

const StyledInput = styled(Input)`
    font-size: 20px;
    font-weight: 500;
`;

const S = {
    Wrapper,
    StyledInput,
};
