import FormElement from "Components/FormElement/FormElement";
import Text from "Components/Text/Text";
import * as S from "./RegisterOption.style";
export default function RegisterSubTitle({ control, watch }) {
    const productSubTitle = watch("product_subtitle");
    const maxLength = 80;
    const length = productSubTitle.length;

    return (
        <S.Wrapper>
            <Text type="h6" subType="p">
                상품 요약
            </Text>
            <S.InputContainer>
                <FormElement
                    control={control}
                    name="product_subtitle"
                    color="none"
                    placeholder="상품요약을 입력하세요."
                    maxLength={maxLength}
                />
                <S.TypoLength>
                    <Text type="h6" subType="p">
                        {length}/{maxLength}
                    </Text>
                </S.TypoLength>
            </S.InputContainer>
        </S.Wrapper>
    );
}
