import { css } from "@emotion/react";
import styled from "@emotion/styled";
import theme from "../../Styles/theme";

const sizeCSS = {
    default: css`
        height: 48px;
    `,

    small: css`
        width: 96px;
        height: 48px;
    `,
    large: css`
        width: 100%;
        height: 48px;
        font-size: 14px;
    `,

    check: css`
        width: 16px;
        height: 16px;
    `,
    thumbnail: css`
        width: 90px;
        height: 90px;
    `,
};

const colorCSS = {
    default: css`
        background-color: ${theme.COLOR.common.white};
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    `,

    disabled: css`
        background-color: ${theme.COLOR.common.gray[400]};
        color: ${theme.COLOR.common.gray[300]};
    `,

    none: css`
        background-color: none;
        outline: none;
        :hover {
            outline: auto;
        }
    `,
};

const shapeCSS = {
    default: css`
        border: none;
        box-sizing: border-box;
        border-radius: 8px;
    `,
};

export const Input = styled.input`
    ${({ size = "default" }) => sizeCSS[size]}
    ${({ color = "default" }) => colorCSS[color]}
    ${({ shape = "default" }) => shapeCSS[shape]}
    flex-shrink: 0;
    text-align: ${({ align }) => (align ? align : "left")};
    padding-right: ${({ align }) => (align ? "32px" : null)};
    &::placeholder {
        color: ${theme.COLOR.common.gray[400]};
        text-align: ${({ p_textAlign }) =>
            p_textAlign ? p_textAlign : "left"};
    }
`;
