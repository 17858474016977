import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { worker } from "./Apis/__mocks__/browser";
import router from "./Routes/router";
import GlobalStyles from "./Styles/global";
import theme from "./Styles/theme";

function App() {
    if (process.env.NODE_ENV === "development") {
        worker.start();
    }

    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    {/* <ErrorBoundary> */}
                    <RouterProvider router={router} />
                    {/* </ErrorBoundary> */}
                </ThemeProvider>
            </RecoilRoot>
        </QueryClientProvider>
    );
}

export default App;
