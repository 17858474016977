import FormElement from "Components/FormElement/FormElement";
import Text from "Components/Text/Text";
import * as S from "./RegisterOption.style";

export default function RegisterDescription({ control }) {
    return (
        <S.Wrapper>
            <Text type="h6" subType="p">
                상세 설명
            </Text>
            <S.InputContainer>
                <FormElement
                    control={control}
                    name="product_description"
                    color="none"
                />
            </S.InputContainer>
        </S.Wrapper>
    );
}
