import styled from "@emotion/styled";
import Input from "Components/Input/Input";
import { FlexAlignStart } from "Styles/common";
import { useController } from "react-hook-form";

export default function CheckBox({ control, name, children, rules }) {
    const { field } = useController({ control, name, rules });

    return (
        <S.Wrapper>
            <label htmlFor={name}>
                <Input id={name} type="checkbox" size="check" {...field} />
                <p id={name}>{children}</p>
            </label>
        </S.Wrapper>
    );
}

const Wrapper = styled.div`
    & > label {
        ${FlexAlignStart}
    }

    & p {
        margin-left: 8px;
    }
`;

const S = {
    Wrapper,
};
